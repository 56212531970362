import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ClassDetails = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('pills-one-tab');

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  const tabs = [
    { id: 'pills-one-tab', label: 'General', target: '#pills-one' },
    { id: 'pills-home-tab', label: 'Product Detail', target: '#pills-home' },
    { id: 'pills-profile-tab', label: 'Instructors', target: '#pills-profile' },
    { id: 'pills-contact-tab', label: 'Attendees', target: '#pills-contact' },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const getCurrentTabLabel = () => {
    const currentTab = tabs.find((tab) => tab.id === activeTab);
    return currentTab ? currentTab.label : 'General';
  };

  return (
    <>
      <div className="outer-border ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              {/*  */}
              <ul
                className="nav nav-tabs mt-3 nav-event-pro tab-nav-list"
                id="pills-tab"
                role="tablist"
                style={{ maxWidth: '550px', width: '100%' }}
              >
                {tabs.map((tab) => (
                  <li key={tab.id} className="nav-item" role="presentation">
                    <div
                      id={tab.id}
                      data-bs-toggle="pill"
                      data-bs-target={tab.target}
                    >
                      <button
                        type="button"
                        className={`nav-link ${activeTab === tab.id ? 'active-tab' : ''}`}
                        onClick={() => handleTabClick(tab.id)}
                      >
                        {tab.label}
                      </button>
                    </div>
                  </li>
                ))}
              </ul>

              {/*  */}
              <div className="card-body">
                <div className="employees employ-set">
                  <div className="back-listing-tab">
                    <a className="button-width" href="/new/class">
                      <div className="custom-action-button orange-outline">
                        <button type="button" className="bgColor white-bg">
                          <img src="../assets/icon/back-icon-page.svg" alt="" />
                        </button>
                      </div>
                    </a>
                    <h3 className="card-top-2">{getCurrentTabLabel()}</h3>
                  </div>
                </div>
              </div>
              <div className="triangle-top">
                <div className="body-text pt-0">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={`tab-pane fade ${activeTab === 'pills-one-tab' ? 'show active' : ''}`}
                      id="pills-one"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="table-responsive mt-3">
                            <table className="table table-time-gun-create">
                              <tbody className="table-time-gun-create__old-even user-all-plan-select text-start">
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Class Name</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>Handgun </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Class Description</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>
                                    <p>This is new</p>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Skill Level</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>ENTRY</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Start Date &amp; Time</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>20-01-2025, 05:43 AM</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>End Date &amp; Time</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>20-01-2025, 07:45 AM</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Door open time</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>08:46 AM</td>
                                </tr>
                                <tr>
                                  <th>
                                    <p className="listing-event">
                                      <span>Session</span>
                                      <span>:</span>
                                    </p>
                                  </th>
                                  <td>30</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${activeTab === 'pills-home-tab' ? 'show active' : ''}`}
                      id="pills-home"
                    >
                      <div className="row mt-4">
                        <div className="card-listing">
                          <div className="card-con">
                            <div className="car-d">
                              <p>
                                Product Name
                                <span>:</span>{' '}
                              </p>
                              <p>Handgun</p>
                            </div>
                            <div className="car-d">
                              <p>
                                Product Description
                                <span>:</span>{' '}
                              </p>
                              <p>
                                A pistol is a type of handgun, characterised by
                                a barrel with an integral chamber. The word
                                &#34;pistol&#34; derives from the Middle French
                                pistolet (1550), meaning a small gun or knife,
                                and first appeared in the English language 1570
                                when early handguns were produced in Europe....
                              </p>
                            </div>
                            <div className="car-d">
                              <p>
                                Price
                                <span>:</span>{' '}
                              </p>
                              <p>3</p>
                            </div>
                          </div>
                          <div className="card-con">
                            <div className="car-d">
                              <p>
                                Product Name
                                <span>:</span>{' '}
                              </p>
                              <p>Handgun</p>
                            </div>
                            <div className="car-d">
                              <p>
                                Product Description
                                <span>:</span>{' '}
                              </p>
                              <p>
                                A pistol is a type of handgun, characterised by
                                a barrel with an integral chamber. The word
                                &#34;pistol&#34; derives from the Middle French
                                pistolet (1550), meaning a small gun or knife,
                                and first appeared in the English language 1570
                                when early handguns were produced in Europe....
                              </p>
                            </div>
                            <div className="car-d">
                              <p>
                                Price
                                <span>:</span>{' '}
                              </p>
                              <p>3</p>
                            </div>
                          </div>
                          <div className="card-con">
                            <div className="car-d">
                              <p>
                                Product Name
                                <span>:</span>{' '}
                              </p>
                              <p>Handgun</p>
                            </div>
                            <div className="car-d">
                              <p>
                                Product Description
                                <span>:</span>{' '}
                              </p>
                              <p>
                                A pistol is a type of handgun, characterised by
                                a barrel with an integral chamber. The word
                                &#34;pistol&#34; derives from the Middle French
                                pistolet (1550), meaning a small gun or knife,
                                and first appeared in the English language 1570
                                when early handguns were produced in Europe....
                              </p>
                            </div>
                            <div className="car-d">
                              <p>
                                Price
                                <span>:</span>{' '}
                              </p>
                              <p>3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${activeTab === 'pills-profile-tab' ? 'show active' : ''}`}
                      id="pills-profile"
                    >
                      <div className=" card-gun-id mt-4">
                        <div className="card user-card triangle-card line-card">
                          <div className="box-bg m-auto bg-black">
                            <div className="bg-hox bg-width-outline">
                              <img
                                src="../assets/images/profile.png"
                                alt="Profile"
                                className="img-size"
                              />
                            </div>
                          </div>

                          <div className="card-body-2">
                            <h4>Jeffery James</h4>
                            <h5>@trainerjeffry</h5>
                            <p></p>
                            <h6 className="text-capitalize">null | NEVER</h6>

                            <div className="gun-bg">
                              <div className="practice-gun">
                                <img
                                  src="../assets/icon/handgun_white.svg"
                                  alt="handgun"
                                />
                              </div>
                              <div className="practice-gun">
                                <img
                                  src="../assets/icon/rifle_white.svg"
                                  alt="rifle"
                                />
                              </div>
                              <div className="practice-gun">
                                <img
                                  src="../assets/icon/shotgun_white.svg"
                                  alt="shotgun"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card user-card triangle-card line-card">
                          <div className="box-bg m-auto bg-black">
                            <div className="bg-hox bg-width-outline">
                              <img
                                src="../assets/images/profile.png"
                                alt="Profile"
                                className="img-size"
                              />
                            </div>
                          </div>

                          <div className="card-body-2">
                            <h4>Jeffery James</h4>
                            <h5>@trainerjeffry</h5>
                            <p></p>
                            <h6 className="text-capitalize">null | NEVER</h6>

                            <div className="gun-bg">
                              <div className="practice-gun">
                                <img
                                  src="../assets/icon/handgun_white.svg"
                                  alt="handgun"
                                />
                              </div>
                              <div className="practice-gun">
                                <img
                                  src="../assets/icon/rifle_white.svg"
                                  alt="rifle"
                                />
                              </div>
                              <div className="practice-gun">
                                <img
                                  src="../assets/icon/shotgun_white.svg"
                                  alt="shotgun"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${activeTab === 'pills-contact-tab' ? 'show active' : ''}`}
                      id="pills-contact"
                    >
                      <div className="row">
                        <div className="col-lg-12 mt-2">
                          <div className="table-responsive">
                            <table className="table table-time-gun-create mobile-responsive mobile">
                              <thead className="head-left">
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Contact</th>
                                </tr>
                              </thead>
                              <tbody className=" table-time-gun-create__old-even">
                                <tr>
                                  <td data-label="Name">Guest one</td>
                                  <td data-label="Email">
                                    <p>guest1@yopmail.com</p>
                                  </td>
                                  <td data-label="Contact">7766557700</td>
                                </tr>
                                <tr>
                                  <td data-label="Name">Guest Two </td>
                                  <td data-label="Email">
                                    <p>guest2@yopmail.com</p>
                                  </td>
                                  <td data-label="Contact">7766557800</td>
                                </tr>
                                <tr>
                                  <td data-label="Name">Guest Three</td>
                                  <td data-label="Email">
                                    <p>guest3@yopmail.com</p>
                                  </td>
                                  <td data-label="Contact">77445504330</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassDetails;
