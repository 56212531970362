import React from 'react';
import PropTypes from 'prop-types';

const UserCard = (props) => {
  return (
    <>
      <div className="card user-card mt-2 triangle-card line-card">
        <div className={` box-bg m-auto ${props.hexBg}`}>
          <div className={`bg-hox ${props.hexOuterLine}`}>
            <img
              src={props.UserImages}
              alt=""
              className={` img-size ${props.imgSize}`}
            />
          </div>
        </div>

        <div className="card-body-2">
          <div className="text-danger fw-bolder cursor-hover d-img-c staff-card-action">
            <img
              src="../assets/icon/edit.svg"
              alt=""
              className="ddd-card"
              data-bs-toggle="modal"
              data-bs-target="#modalLane2"
              onClick={() => props.setSelectedInstructor(props.instructor)}
            />
            <img
              src="../assets/icon/delete.svg"
              alt=""
              className="ddd-card"
              data-bs-toggle="modal"
              data-bs-target="#deleteModal"
              onClick={() =>
                props.handleDelete(props.instructor.id, 'instructor')
              }
            />
          </div>

          <h4>{props.Heading}</h4>
          <h5>{props.SubHeading}</h5>
          <p>{props.Text}</p>
          <h6 className="text-capitalize">{props.Experience}</h6>
          <div className="gun-bg">
            <div className="practice-gun">
              <img src="../assets/icon/handgun_white.svg" alt="" />
            </div>
            <div className="practice-gun">
              <img src="../assets/icon/rifle_white.svg" alt="" />
            </div>
            <div className="practice-gun">
              <img src="../assets/icon/shotgun_white.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserCard.propTypes = {
  props: PropTypes.object,
  hexBg: PropTypes.string,
  hexOuterLine: PropTypes.string,
  UserImages: PropTypes.string,
  imgSize: PropTypes.string,
  Heading: PropTypes.string,
  SubHeading: PropTypes.string,
  Text: PropTypes.string,
  Experience: PropTypes.string,
  handleDelete: PropTypes.func,
  instructor: PropTypes.object,
  setSelectedInstructor: PropTypes.func,
};

export default UserCard;
