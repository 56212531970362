import React from 'react';
import { useNavigate } from 'react-router';

const CardDesk = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="card-body">
        <h3>Range Desk to do </h3>
        <div className="triangle-top">
          <div className="body-text body-font  pt-4 body-p-b">
            <div className="row">
              <div className="col-6 col-md-6 ">
                <p
                  className="cursor-p"
                  onClick={() => navigate('/ranges/user-details')}
                >
                  Pending FPE
                </p>
                <h4>3</h4>
              </div>
              <div className="col-6 col-md-6">
                <p
                  className="cursor-p"
                  onClick={() => navigate('/ranges/id-verification-list')}
                >
                  Pending ID Verification
                </p>
                <h4>2</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDesk;
