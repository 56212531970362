import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { emailRegExp, phoneRegExp } from '../Utils/common';
import { addAddress, uploadFile } from '../Utils/upload';
import AddressAutocomplete from '../Components/AddressAutocomplete';
import ButtonLoader from '../Components/ButtonLoader';
import Toast from '../Utils/Toast';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import Label from '../Components/Label';
import ImageUploadInput from '../Components/ImageUploadInput';
import { USER_CLASS } from '../Utils/ModelClasses';
import { USER_PROFILE_PICTURE_TYPE } from '../Utils/UploadType';
import { ADD_TRAINER_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Validation schema
const TrainerRangeSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  password: Yup.string().required('Required'),
  dob: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  bio: Yup.string().required('Required'),
  skillLevel: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
});

const InstructorForm = ({
  fetchInstructorList,
  selectedInstructor,
  setSelectedInstructor,
}) => {
  const [userName, setUserName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [staff, setStaff] = useState('Staff');
  const [bio, setBio] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [addressObject, setAddressObject] = useState({});
  const [address, setAddress] = useState({});
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState({});

  useEffect(() => {
    if (selectedInstructor?.id) {
      setEmail(selectedInstructor?.user?.email);
      setPhoneNumber(selectedInstructor?.user?.phone_number);
      setFirstName(selectedInstructor?.user?.first_name);
      setLastName(selectedInstructor?.user?.last_name);
      setDob(selectedInstructor?.user?.dob);
      setGender(selectedInstructor?.user?.gender);
      setPassword(selectedInstructor?.user?.password);
      setUserName(selectedInstructor?.user?.username);
      setBio(selectedInstructor?.user?.username);
      setAddress(selectedInstructor?.user?.billing_address);
      setImageData(selectedInstructor?.user?.profile_picture);
      //   setSkillLevel(selectedInstructor?.skill_level);
      //   setStaff('Staff');
    }
  }, [selectedInstructor]);

  const validate = () => {
    try {
      TrainerRangeSchema.validateSync(
        {
          userName,
          firstName,
          lastName,
          email,
          password,
          dob,
          gender,
          bio,
          skillLevel,
          phoneNumber,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const payload = {
        username: userName,
        email: email,
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        gender: gender,
        bio: bio,
        password: password,
      };

      let response;
      if (selectedInstructor?.id) {
        // Update existing instructor
        response = await axiosInstance.patch(
          ADD_TRAINER_ENDPOINT + '/' + selectedInstructor.user_id,
          payload
        );
      } else {
        // Create new instructor
        response = await axiosInstance.post(ADD_TRAINER_ENDPOINT, payload);
      }

      if (response.data.code === 200) {
        let requestAddressObj = { ...addressObject };
        requestAddressObj.contact_number = phoneNumber;
        requestAddressObj.contact_person_name = firstName + ' ' + lastName;
        if (file) {
          await uploadFile(
            USER_CLASS,
            response.data.data.id,
            USER_PROFILE_PICTURE_TYPE,
            file
          );
        }
        await addAddress(USER_CLASS, response.data.data.id, requestAddressObj);
        handleReset();
        const modalClose = document.getElementsByClassName('close-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        fetchInstructorList();
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setUserName('');
    setLastName('');
    setFirstName('');
    setEmail('');
    setPassword('');
    setDob('');
    setGender('');
    setBio('');
    setSkillLevel('');
    setGeneralError('');
    setErrors({});
    setStaff('Staff');
    setPhoneNumber('');
    setSelectedInstructor({});
  };

  return (
    <div className="modal fade" id="modalLane2" data-bs-backdrop="static">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="lane-name">
              {selectedInstructor?.id
                ? 'Update Instructor'
                : 'Create Instructor'}
            </h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body modal-height">
              <div className="row">
                <div className="col-lg-6">
                  <Label labelHeading="Username*" />
                  <div className="tickets">
                    <input
                      type="text"
                      className="form-control input-theme"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName}
                    />
                    {errors.userName && (
                      <div className="error">{errors.userName}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <Label labelHeading="Phone:" />
                  <div className="tickets">
                    <input
                      type="phone"
                      className="form-control input-theme"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errors.phoneNumber && (
                      <div className="error">{errors.phoneNumber}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <Label labelHeading="First Name*" />
                  <div className="tickets">
                    <input
                      type="text"
                      className="form-control input-theme"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                    {errors.firstName && (
                      <div className="error">{errors.firstName}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <Label labelHeading="Last Name*" />
                  <div className="tickets">
                    <input
                      type="text"
                      className="form-control input-theme"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                    {errors.lastName && (
                      <div className="error">{errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <Label labelHeading="Email*" />
                  <div className="tickets">
                    <input
                      type="email"
                      className="form-control input-theme"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                </div>
                {/* {!selectedInstructor?.id && ( */}
                <div className="col-lg-6">
                  <Label labelHeading="Password*" />
                  <div className="tickets">
                    <input
                      type="password"
                      className="form-control input-theme"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    {errors.password && (
                      <div className="error">{errors.password}</div>
                    )}
                  </div>
                </div>
                {/* )} */}
                <div className="col-lg-6">
                  <Label labelHeading="Dob" />
                  <div className="tickets">
                    <input
                      type="date"
                      className="form-control input-theme"
                      onChange={(e) => setDob(e.target.value)}
                      value={dob}
                    />
                    {errors.dob && <div className="error">{errors.dob}</div>}
                  </div>
                </div>
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <Label labelHeading="Gender*" />
                  <div className="select-input tickets">
                    <select
                      name="Gender"
                      id="Gender*"
                      className="form-select mb-0"
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                    >
                      <option value="">Select an option</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Non Binary</option>
                    </select>
                    {errors.gender && (
                      <div className="error">{errors.gender}</div>
                    )}
                  </div>
                </div>
                <AddressAutocomplete
                  handleAddress={setAddressObject}
                  addressList={address}
                />

                <div className="col-lg-6 mb-3 mb-lg-0">
                  <Label labelHeading="Type*" />
                  <div className="select-input tickets">
                    <select
                      name="Staff"
                      id="Staff*"
                      className="form-select mb-0"
                      onChange={(e) => setStaff(e.target.value)}
                      value={staff}
                    >
                      <option value="">Select an option</option>
                      <option value="Staff">Staff</option>
                      <option value="Rover">Rover</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 mb-3">
                  <Label labelHeading="Skill level*" />
                  <div className="select-input tickets">
                    <select
                      name="Skill"
                      id="Skill"
                      className="form-select mb-0"
                      onChange={(e) => setSkillLevel(e.target.value)}
                      value={skillLevel}
                    >
                      <option value="">Select an option</option>
                      <option value="Novice/Never Ever">
                        Novice/Never Ever
                      </option>
                      <option value="Beginner/In-training">
                        Beginner/In-training
                      </option>
                      <option value="Proficient/Intermediate">
                        Proficient/Intermediate
                      </option>
                      <option value="Advanced/Specialist">
                        Advanced/Specialist
                      </option>
                      <option value="Professional">Professional</option>
                    </select>
                    {errors.skillLevel && (
                      <div className="error">{errors.skillLevel}</div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <Label labelHeading="Bio" />
                  <div className="tickets-2 pb-0">
                    <textarea
                      className="form-control input-theme"
                      rows="3"
                      onChange={(e) => setBio(e.target.value)}
                      value={bio}
                    ></textarea>
                    {errors.bio && <div className="error">{errors.bio}</div>}
                  </div>
                </div>
                <div className="col-12">
                  <Label labelHeading="Avatar" />
                  <ImageUploadInput setFile={setFile} imageData={imageData} />
                </div>
              </div>
              {generalError && (
                <p className="error text-center mt-3">{generalError}</p>
              )}
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <ButtonLoader
                active="orange-outline"
                bgChange="white-bg w-pd-add save-w"
                type="submit"
                isLoading={isLoading}
              >
                {selectedInstructor?.id ? 'Update' : 'Save'}
              </ButtonLoader>

              <Link
                to="#"
                data-bs-dismiss="modal"
                className="close-modal"
                onClick={handleReset}
              >
                <ButtonRemoveImage
                  Heading="Close"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

InstructorForm.propTypes = {
  selectedInstructor: PropTypes.object,
  fetchInstructorList: PropTypes.func,
  setSelectedInstructor: PropTypes.func,
};

export default InstructorForm;
