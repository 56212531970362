import { Link } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';
import Label from '../Components/Label';
import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useRef, useState } from 'react';
import axiosInstance from '../Utils/axiosInstance';
import { MEMBERSHIP_PLANS_ENDPOINT } from '../Utils/Endpoints';
import Logger from '../Utils/Logger';
import PhoneInputView from '../Components/PhoneInput';
import MemberModal from '../Components/MemberModal';
import ButtonLoader from '../Components/ButtonLoader';
import TerminalComponent from '../Components/Payment/TerminalComponent';
import StripePayment from '../Components/Payment/StripePayment';
import * as Yup from 'yup';
import { emailRegExp, phoneRegExp } from '../Utils/common';

const validationSchema = Yup.object().shape({
  selectedMembershipPlan: Yup.array().required('Membership plan is required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  dob: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
});

const MembershipPurchase = () => {
  const [selectedMembershipPlan, setSelectedMembershipPlan] = useState([]);
  const [membershipPlan, setMembershipPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [errors, setErrors] = useState({});
  const [familyMembers, setFamilyMembers] = useState([]);
  const [activeTab, setActiveTab] = useState('cash');
  // Payment Ref
  const stripeRef = useRef(null); // To access the Stripe form in child component
  // const terminalRef = useRef(null);

  useEffect(() => {
    fetchMembershipPlan();
  }, []);

  const fetchMembershipPlan = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(MEMBERSHIP_PLANS_ENDPOINT);
      if (response.data.code === 200) {
        // Filter for active plans only (status === 1)
        const activePlans = response.data.data.filter(
          (plan) => plan.status === 1
        );
        setMembershipPlan(activePlans);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUsers = (newMember) => {
    setFamilyMembers([...familyMembers, newMember]);
  };

  const handleRemoveMember = (memberToRemove) => {
    setFamilyMembers(
      familyMembers.filter((member) => member.id !== memberToRemove.id)
    );
  };

  const validateField = async (field, value) => {
    try {
      await validationSchema.validateAt(field, {
        selectedMembershipPlan,
        firstName,
        lastName,
        email,
        dob,
        phoneNumber,
        gender,
        [field]: value,
      });
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    } catch (err) {
      setErrors((prev) => ({ ...prev, [field]: err.message }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        selectedMembershipPlan,
        firstName,
        lastName,
        email,
        dob,
        phoneNumber,
        gender,
      };

      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      // Proceed with form submission
      console.log(familyMembers);
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
    }
  };

  const tabs = [
    {
      id: 'terminal',
      title: 'TERMINAL',
      icon: '../assets/images/credit-card-min.svg',
      content: (
        <div className="payment-card-tm">
          <div className="card-payment-ab">
            <h4 className="cash-price">${selectedMembershipPlan[0]?.price}</h4>
            <div className="tickets">
              <input
                type="name"
                className="form-control input-theme"
                placeholder="Please Select location"
              />
            </div>
            <div className="tickets">
              <input
                type="name"
                className="form-control input-theme"
                placeholder="Please Select reader"
              />
            </div>
            <TerminalComponent />
          </div>
          <div className="pay-pay">
            <div className="button-range-create mt-5">
              <ButtonLoader
                Heading="Book"
                active="orange-outline"
                bgChange="white-bg w-pd-add save-w"
                type="submit"
                isLoading={isLoading}
              >
                Book
              </ButtonLoader>
            </div>
          </div>
        </div>
      ),
      disabled: true,
    },
    {
      id: 'card',
      title: 'CARDS',
      icon: '../assets/images/stripe-com-min.svg',
      content: (
        <StripePayment
          selectedBay={selectedMembershipPlan[0]}
          //   handleStripeSubmit={handleStripeSubmit}
          ref={stripeRef}
        />
      ),
      disabled: true,
    },
    {
      id: 'cash',
      title: 'CASH',
      icon: '../assets/images/cash-payment-min.svg',
      content: (
        <div className="payment-card-tm">
          <div className="card-payment-ab">
            <h4 className="cash-price">${selectedMembershipPlan[0]?.price}</h4>
          </div>
          <div className="pay-pay">
            <div className="button-range-create">
              <ButtonLoader
                Heading="Book"
                active="orange-outline"
                bgChange="white-bg w-pd-add"
                type="submit"
                isLoading={isLoading}
              >
                Purchase
              </ButtonLoader>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="outer-border outer-over">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card-body">
              <div className="employees employ-set">
                <div className="back-listing-tab">
                  <h3 className="card-top-2 h-set-mob">Membership Purchase</h3>
                </div>
                <div className="all-date-selected new-back-btn-add">
                  <Link
                    to="/ranges/lane-check-list"
                    className="button-width b-p"
                  >
                    <ButtonAdd
                      Heading=""
                      images="../assets/icon/back-icon-page.svg"
                      active="orange-outline"
                      bgChange="white-bg "
                    />
                  </Link>
                </div>
              </div>
              <form className="triangle-top" onSubmit={handleSubmit}>
                <div className="body-text book-slot-w">
                  <div className="row">
                    <div className="col-lg-12">
                      <Label labelHeading="Choose Membership Plan" />
                      <div className="tickets">
                        <Multiselect
                          isObject={true}
                          options={membershipPlan}
                          selectedValues={selectedMembershipPlan}
                          // showCheckbox
                          onSelect={(option) =>
                            setSelectedMembershipPlan(option)
                          }
                          onRemove={(option) =>
                            setSelectedMembershipPlan(option)
                          }
                          isLoading={isLoading}
                          displayValue="name"
                          placeholder="Select Membership Plan"
                          required
                          disabled={isLoading || membershipPlan?.length === 0}
                          selectionLimit={1}
                        />
                        {errors.selectedMembershipPlan && (
                          <div className="error">
                            {errors.selectedMembershipPlan}
                          </div>
                        )}
                      </div>
                    </div>
                    <h6 className="mt-3 text-left">Add User Details </h6>
                    <div className="col-lg-6">
                      <Label labelHeading="First Name" />
                      <div className="tickets">
                        <input
                          type="text"
                          className="form-control input-theme"
                          placeholder=""
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            validateField('firstName', e.target.value);
                          }}
                        />
                        {errors.firstName && (
                          <div className="error">{errors.firstName}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Label labelHeading="Last Name" />
                      <div className="tickets">
                        <input
                          type="text"
                          className="form-control input-theme"
                          placeholder=""
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            validateField('lastName', e.target.value);
                          }}
                        />
                        {errors.lastName && (
                          <div className="error">{errors.lastName}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Label labelHeading="Email" />
                      <div className="tickets">
                        <input
                          type="email"
                          className="form-control input-theme"
                          placeholder=""
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            validateField('email', e.target.value);
                          }}
                        />
                        {errors.email && (
                          <div className="error">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Label labelHeading="Dob" />
                      <div className="tickets">
                        <input
                          type="date"
                          className="form-control input-theme"
                          onChange={(e) => {
                            setDob(e.target.value);
                            validateField('dob', e.target.value);
                          }}
                          value={dob}
                        />
                        {errors.dob && (
                          <div className="error">{errors.dob}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Label labelHeading="Phone" />
                      <div className="tickets">
                        <PhoneInputView
                          phoneNumber={phoneNumber}
                          setPhoneNumber={(value) => {
                            setPhoneNumber(value);
                            validateField('phoneNumber', value);
                          }}
                        />
                        {errors.phoneNumber && (
                          <div className="error">{errors.phoneNumber}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Label labelHeading="Gender" />
                      <div className="select-input tickets">
                        <select
                          className="form-select mb-0"
                          value={gender}
                          onChange={(e) => {
                            setGender(e.target.value);
                            validateField('gender', e.target.value);
                          }}
                        >
                          <option value="">Gender</option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="OTHER">Non Binary</option>
                        </select>
                        {errors.gender && (
                          <div className="error">{errors.gender}</div>
                        )}
                      </div>
                    </div>

                    {selectedMembershipPlan[0]?.additional_family_members > 0 &&
                      familyMembers.length <
                        selectedMembershipPlan[0]
                          ?.additional_family_members && (
                        <div className="participants-button-membership mt-5">
                          <Link
                            to="#"
                            className="button-width"
                            data-bs-toggle="modal"
                            data-bs-target="#memberModal"
                          >
                            <ButtonAdd
                              images="../assets/icon/plaus_icon.svg"
                              Heading="ADD Family Member"
                              active="orange-outline"
                              bgChange="white-bg"
                            />
                          </Link>
                        </div>
                      )}
                    <div className="user-count-select">
                      {familyMembers?.map((item, index) => (
                        <div className="user-box" key={index}>
                          <p>{item?.displayValue}</p>
                          <p className="cr-s-i">
                            <img
                              src="../assets/icon/cross-svgrepo-com.svg"
                              alt=""
                              onClick={() => handleRemoveMember(item)}
                              style={{ cursor: 'pointer' }}
                            />
                          </p>
                        </div>
                      ))}
                    </div>
                    {/* {generalError && (
                      <p className="error text-center mt-3">{generalError}</p>
                    )} */}
                    {selectedMembershipPlan?.length > 0 && (
                      <div className="col-12 mt-5">
                        <ul
                          className="nav nav-tabs payment-card"
                          id="myTab"
                          role="tablist"
                        >
                          {tabs.map((tab) => (
                            <li
                              key={tab.id}
                              className="nav-item"
                              role="presentation"
                            >
                              <div
                                className={`custom-action-button  ${tab.disabled ? 'dis-btn-tab' : ''} `}
                                id={`${tab.id}-tab`}
                                type="button"
                                role="tab"
                                aria-controls={!tab.disabled && tab.id}
                                aria-selected={
                                  tab.disabled ? false : activeTab === tab.id
                                }
                                onClick={() =>
                                  !tab.disabled && setActiveTab(tab.id)
                                }
                                {...(!tab.disabled && {
                                  'data-bs-toggle': 'tab',
                                  'data-bs-target': `#${tab.id}`,
                                })}
                              >
                                <button
                                  type="button"
                                  className={`nav-link ${activeTab === tab.id ? 'active' : ''}  bgChange`}
                                  disabled={tab.disabled}
                                >
                                  <span>
                                    <img src={tab.icon} alt="" />
                                  </span>
                                  <span>{tab.title}</span>
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="tab-content mt-5 " id="myTabContent">
                          {tabs.map((tab) => (
                            <div
                              key={tab.id}
                              className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
                              id={tab.id}
                              role="tabpanel"
                              aria-labelledby={`${tab.id}-tab`}
                            >
                              {tab.content}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <MemberModal
        handleUpdateUsers={handleUpdateUsers}
        maxMembers={selectedMembershipPlan[0]?.additional_family_members}
        currentMembersCount={familyMembers.length + 1}
      />
    </div>
  );
};

export default MembershipPurchase;
