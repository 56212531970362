import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StripePayment from './StripePayment';
import TerminalComponent from './TerminalComponent';
import ButtonLoader from '../ButtonLoader';
import { Link } from 'react-router-dom';
import ButtonRemoveImage from '../ButtonRemoveImage';
import { PAYMENT_METHOD_LIST_ENDPOINT } from '../../Utils/Endpoints';
import axiosInstance from '../../Utils/axiosInstance';
import Logger from '../../Utils/Logger';
import PaymentService from '../../Utils/PaymentService';
import { LANE_CLASS } from '../../Utils/ModelClasses';

const PaymentModule = ({
  selectedBay,

  selectedUsers,
  laneId,
  rangeId,
  selectedSlot,
  isLoading,
  handleClose,
  onSuccess,
  onError,
}) => {
  const [activeTab, setActiveTab] = useState('cash');
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(isLoading || false);

  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  useEffect(() => {
    setIsLoadingData(isLoading);
  }, [isLoading]);

  const fetchPaymentMethod = async () => {
    try {
      setIsLoadingData(true);
      const response = await axiosInstance.get(PAYMENT_METHOD_LIST_ENDPOINT);
      if (response.data.code === 200) {
        console.log('Payment methods:', response.data.data);
        setPaymentMethodList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const getPaymentMethodById = (id) => {
    const method = paymentMethodList.find((method) => method.id === id);
    console.log('Payment method for id', id, ':', method);
    return method;
  };

  const handleCashPayment = async () => {
    try {
      setIsLoadingData(true);

      // Prepare order data
      const orderData = {
        items: [
          {
            owner_class: LANE_CLASS,
            owner_id: laneId,
            quantity: 1,
            details: {
              participant_ids: selectedUsers[1] ? [selectedUsers[1].id] : [],
            },
          },
        ],
        user_id: selectedUsers[0]?.id,
      };

      // Process payment using PaymentService
      await PaymentService.handlePayment({
        rangeId,
        orderData,
        paymentMethod: 'cash',
        selectedSlot,
        onSuccess,
        onError,
      });
    } catch (error) {
      Logger.error('Error processing cash payment:', error);
      onError?.(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const tabs = [
    {
      id: 'terminal',
      title: 'TERMINAL',
      icon: '../assets/images/credit-card-min.svg',
      content: (
        <div className="payment-card-tm">
          <div className="card-payment-ab">
            <h4 className="cash-price">
              ${selectedBay?.base_reservation_cost?.toFixed(2)}
            </h4>
            <div className="tickets">
              <input
                type="name"
                className="form-control input-theme"
                placeholder="Please Select location"
              />
            </div>
            <div className="tickets">
              <input
                type="name"
                className="form-control input-theme"
                placeholder="Please Select reader"
              />
            </div>
            <TerminalComponent />
          </div>
          <div className="pay-pay">
            <div className="button-range-create mt-5">
              <ButtonLoader
                Heading="Book"
                active="orange-outline"
                bgChange="white-bg w-pd-add save-w"
                type="submit"
                isLoading={isLoadingData}
              >
                Book
              </ButtonLoader>
              <Link
                to="/ranges/lane-check-list"
                className="close-solcial-link-modal"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                <ButtonRemoveImage
                  Heading="Cancel"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      ),
      disabled: !getPaymentMethodById(1)?.details?.terminal_active,
    },
    {
      id: 'card',
      title: 'CARDS',
      icon: '../assets/images/stripe-com-min.svg',
      content: (
        <StripePayment
          stripeData={getPaymentMethodById(1)}
          selectedBay={selectedBay}
          selectedUsers={selectedUsers}
          laneId={laneId}
          rangeId={rangeId}
          selectedSlot={selectedSlot}
          onSuccess={onSuccess}
          onError={onError}
        />
      ),
      disabled: !getPaymentMethodById(1)?.details?.wallet_active,
    },
    {
      id: 'cash',
      title: 'CASH',
      icon: '../assets/images/cash-payment-min.svg',
      content: (
        <div className="payment-card-tm">
          <div className="card-payment-ab">
            <h4 className="cash-price">
              ${selectedBay?.base_reservation_cost?.toFixed(2)}
            </h4>
          </div>
          <div className="pay-pay">
            <div className="button-range-create">
              <ButtonLoader
                Heading="Book"
                active="orange-outline"
                bgChange="white-bg w-pd-add"
                type="submit"
                isLoading={isLoadingData}
                onClick={handleCashPayment}
              >
                Book
              </ButtonLoader>

              <Link
                to="/ranges/lane-check-list"
                className="close-solcial-link-modal"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >
                <ButtonRemoveImage
                  Heading="Cancel"
                  active="gary-light-outline"
                  bgChange="gary-bg w-pd-add"
                />
              </Link>
            </div>
          </div>
        </div>
      ),
    },
  ];
  // console.log(getPaymentMethodById(1));
  return (
    <div className="col-12 mt-5">
      <ul className="nav nav-tabs payment-card" id="myTab" role="tablist">
        {tabs.map((tab) => (
          <li key={tab.id} className="nav-item" role="presentation">
            <div
              className={`custom-action-button ${tab.disabled ? 'dis-btn-tab' : ''}`}
              id={`${tab.id}-tab`}
              type="button"
              role="tab"
              aria-controls={!tab.disabled && tab.id}
              aria-selected={tab.disabled ? false : activeTab === tab.id}
              onClick={() => !tab.disabled && setActiveTab(tab.id)}
              {...(!tab.disabled && {
                'data-bs-toggle': 'tab',
                'data-bs-target': `#${tab.id}`,
              })}
            >
              <button
                type="button"
                className={`nav-link ${activeTab === tab.id ? 'active' : ''} bgChange`}
                disabled={tab.disabled}
              >
                <span>
                  <img src={tab.icon} alt="" />
                </span>
                <span>{tab.title}</span>
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="tab-content mt-5" id="myTabContent">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
            id={tab.id}
            role="tabpanel"
            aria-labelledby={`${tab.id}-tab`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

PaymentModule.propTypes = {
  selectedBay: PropTypes.shape({
    id: PropTypes.number,
    base_reservation_cost: PropTypes.number,
    member_reservation_cost: PropTypes.number,
    start_unix_timestamp: PropTypes.number,
    end_unix_timestamp: PropTypes.number,
  }),
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  laneId: PropTypes.string,
  rangeId: PropTypes.string,
  selectedSlot: PropTypes.shape({
    start_unix_timestamp: PropTypes.number.isRequired,
    end_unix_timestamp: PropTypes.number.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default PaymentModule;
