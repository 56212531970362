import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  // AXIS_CONFIG_ENDPOINT,
  MEMBERSHIP_PLANS_ENDPOINT,
} from '../Utils/Endpoints';
import Logger from '../Utils/Logger';
import axiosInstance from '../Utils/axiosInstance';
import SkeletonLoader from '../Components/SkeletonLoader';
import RangeNotFound from '../Components/RangeNotFound';
import ButtonAdd from '../Components/ButtonAdd';
import MembershipModal from '../Components/MembershipModal';
import DeleteModal from '../Components/DeleteModal';
// import { USER_CLASS } from '../Utils/ModelClasses';
// import { getLoginUser } from '../Utils/LocalStorage';

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  // const [currentUser] = useState(getLoginUser());
  const [axisEnabled, setAxisEnabled] = useState(false);
  useEffect(() => {
    fetchPlans();
    // fetchAxisConfig();
  }, []);

  const fetchPlans = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(MEMBERSHIP_PLANS_ENDPOINT);
      if (response.data.code === 200) {
        setPlans(response.data.data);
        setAxisEnabled(response.data.data.length > 15);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchAxisConfig = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axiosInstance.get(
  //       AXIS_CONFIG_ENDPOINT +
  //         `?owner_class=${USER_CLASS}&owner_id=${currentUser?.id}`
  //     );
  //     if (response.data.code === 200) {
  //       const responseData = response.data.data;
  //       setAxisEnabled(responseData.AXIS?.form?.axis_enabled?.value || false);
  //     }
  //   } catch (error) {
  //     Logger.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleEditRangeClick = (plan) => {
    setSelectedMembership(plan);
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Membership</h3>
                  {!axisEnabled && (
                    <div className="all-date-selected">
                      <Link
                        to="#"
                        className="button-width"
                        data-bs-toggle="modal"
                        data-bs-target="#membershipModal"
                      >
                        <ButtonAdd
                          Heading="ADD"
                          images="../assets/icon/plaus_icon.svg"
                          active="orange-outline"
                          bgChange="white-bg w-pd-add"
                        />
                      </Link>
                    </div>
                  )}
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    {isLoading && <SkeletonLoader />}
                    {plans.length === 0 && !isLoading && (
                      <RangeNotFound title="Plans" isInfo={true} />
                    )}
                    {plans.length > 0 && !isLoading && (
                      <div className="table-responsive w-100">
                        <table className="table table-time-gun-create mobile-responsive">
                          <thead className="head-left">
                            <tr>
                              <th className="text-start">NAME</th>
                              <th className="text-center">Membership Length</th>
                              <th>STATUS</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody className="table-time-gun-create__old-even">
                            {plans.map((plan) => (
                              <tr key={plan.id}>
                                <td data-label="Name" className="text-start">
                                  {plan.name}
                                </td>
                                <td
                                  data-label="Membership Length"
                                  className="color-black"
                                >
                                  {plan.length_months
                                    ? 'Month (' + plan.length_months + ')'
                                    : '-'}
                                </td>
                                <td data-label="Active">
                                  {plan.status ? 'Active' : 'Inactive'}
                                </td>
                                <td data-label="Actions">
                                  <div className="mt-0 mt-lg-1 justify-content-lg-center d-flex align-items-center gap-2 gap-lg-2">
                                    <Link
                                      to="/plan-individual"
                                      state={{ plan }}
                                      className="text-center color-theme text-left-mob"
                                    >
                                      <img
                                        src="../assets/icon/view-eye.svg"
                                        alt=""
                                        style={{ width: '25px' }}
                                      />
                                    </Link>
                                    {!axisEnabled && (
                                      <>
                                        <Link
                                          className="or-col"
                                          data-bs-toggle="modal"
                                          data-bs-target="#membershipModal"
                                          onClick={() =>
                                            handleEditRangeClick(plan)
                                          }
                                        >
                                          <img
                                            src="../assets/icon/edit.svg"
                                            alt="edit"
                                            style={{
                                              width: '23px',
                                            }}
                                          />
                                        </Link>
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                          className="dan-col"
                                          onClick={() =>
                                            setSelectedData({
                                              id: plan.id,
                                              type: 'membership_list',
                                            })
                                          }
                                        >
                                          <img
                                            className="ddd-card"
                                            src="../assets/icon/delete.svg"
                                            alt="delete"
                                            style={{
                                              width: '25px',
                                            }}
                                          />
                                        </Link>
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MembershipModal
        fetchMembershipPlans={fetchPlans}
        selectedMembership={selectedMembership}
        setSelectedMembership={setSelectedMembership}
      />
      <DeleteModal selectedData={selectedData} handleUpdateList={fetchPlans} />
    </>
  );
};

export default Plans;
