import { Link } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';
import Label from '../Components/Label';
import { useState } from 'react';
import AddressAutocomplete from '../Components/AddressAutocomplete';
import ButtonLoader from '../Components/ButtonLoader';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import ImageUploadInput from '../Components/ImageUploadInput';
import * as Yup from 'yup';
import { emailRegExp, phoneRegExp } from '../Utils/common';

// Validation schema
const staffSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  password: Yup.string().required('Required'),
  dob: Yup.string().required('Required'),
  gender: Yup.string().required('Required'),
  bio: Yup.string().required('Required'),
  skillLevel: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
});

const Staff = () => {
  const [userName, setUserName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [type, setType] = useState('');
  const [bio, setBio] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [, setAddressObject] = useState({});
  const [, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = () => {
    setUserName('');
    setLastName('');
    setFirstName('');
    setPhoneNumber('');
    setEmail('');
    setPassword('');
    setDob('');
    setGender('');
    setBio('');
    setSkillLevel('');
    setGeneralError('');
    setErrors({});
    setAddressObject({});
    setType('');
  };

  const validate = () => {
    try {
      staffSchema.validateSync(
        {
          userName,
          firstName,
          lastName,
          email,
          password,
          dob,
          gender,
          bio,
          skillLevel,
          phoneNumber,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const modalClose = document.getElementsByClassName('close-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Staff</h3>
                  <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                    <Link
                      to="#"
                      className="button-width"
                      data-bs-toggle="modal"
                      data-bs-target="#modalLane2"
                    >
                      <ButtonAdd
                        Heading="ADD"
                        images="../assets/icon/plaus_icon.svg"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>

                <div className="triangle-top">
                  <div className="body-text ins-card">
                    {/* {instructorList?.length === 0 && !isLoading && (
                      <RangeNotFound title="Staff" />
                    )} */}
                    {/* {isLoading && <SkeletonLoader />}
                    <div className=" card-gun-id">
                      {instructorList?.length > 0 &&
                        !isLoading &&
                        instructorList.map((instructor, index) => (
                          <div className="card user-card mt-2 triangle-card line-card">
                            <div className={` box-bg m-auto bg-black`}>
                              <div className={`bg-hox bg-width-outline`}>
                                <img
                                  src="../assets/images/first.jpeg"
                                  alt=""
                                  className={` img-size `}
                                />
                              </div>
                            </div>

                            <div className="card-body-2">
                              <div className="text-danger fw-bolder cursor-hover d-img-c staff-card-action">
                                <img
                                  src="../assets/icon/edit.svg"
                                  alt=""
                                  className="ddd-card"
                                />
                                <img
                                  src="../assets/icon/delete.svg"
                                  alt=""
                                  className="ddd-card"
                                />
                              </div>

                              <h4>{props.Heading}</h4>
                              <h5>{props.SubHeading}</h5>
                              <p>
                                Atlanta, GA | Stoddard´s Range & Guns Profile
                                100% Complete
                              </p>
                              <h6 className="text-capitalize">
                                INSTRUCTOR | 12 Years Experience
                              </h6>
                              <div className="gun-bg">
                                <div className="practice-gun">
                                  <img
                                    src="../assets/icon/handgun_white.svg"
                                    alt=""
                                  />
                                </div>
                                <div className="practice-gun">
                                  <img
                                    src="../assets/icon/rifle_white.svg"
                                    alt=""
                                  />
                                </div>
                                <div className="practice-gun">
                                  <img
                                    src="../assets/icon/shotgun_white.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div> */}
                    <div className=" card-gun-id">
                      <div className="card user-card mt-2 triangle-card line-card">
                        <div className={` box-bg m-auto bg-black`}>
                          <div className={`bg-hox bg-width-outline`}>
                            <img
                              src="../assets/images/first.jpeg"
                              alt=""
                              className={` img-size `}
                            />
                          </div>
                        </div>

                        <div className="card-body-2">
                          <div className="text-danger fw-bolder cursor-hover d-img-c staff-card-action">
                            <img
                              src="../assets/icon/edit.svg"
                              alt=""
                              className="ddd-card"
                            />
                            <img
                              src="../assets/icon/delete.svg"
                              alt=""
                              className="ddd-card"
                            />
                          </div>

                          <h4>Jeff James</h4>
                          <h5>@jeffjamesnow</h5>
                          <p>
                            Atlanta, GA | Stoddard´s Range & Guns Profile 100%
                            Complete
                          </p>
                          <h6 className="text-capitalize">
                            INSTRUCTOR | 12 Years Experience
                          </h6>
                          <div className="gun-bg">
                            <div className="practice-gun">
                              <img
                                src="../assets/icon/handgun_white.svg"
                                alt=""
                              />
                            </div>
                            <div className="practice-gun">
                              <img
                                src="../assets/icon/rifle_white.svg"
                                alt=""
                              />
                            </div>
                            <div className="practice-gun">
                              <img
                                src="../assets/icon/shotgun_white.svg"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalLane2">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="lane-name">Create Staff</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height">
                <div className="row">
                  <div className="col-lg-6">
                    <Label labelHeading="Username*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setUserName(e.target.value)}
                        value={userName}
                      />
                      {errors.userName && (
                        <div className="error">{errors.userName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Phone:" />
                    <div className="tickets">
                      <input
                        type="phone"
                        className="form-control input-theme"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      {errors.phoneNumber && (
                        <div className="error">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="First Name*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                      {errors.firstName && (
                        <div className="error">{errors.firstName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Last Name*" />
                    <div className="tickets">
                      <input
                        type="text"
                        className="form-control input-theme"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                      {errors.lastName && (
                        <div className="error">{errors.lastName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Email*" />
                    <div className="tickets">
                      <input
                        type="email"
                        className="form-control input-theme"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      {errors.email && (
                        <div className="error">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Password*" />
                    <div className="tickets">
                      <input
                        type="password"
                        className="form-control input-theme"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      {errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Label labelHeading="Dob" />
                    <div className="tickets">
                      <input
                        type="date"
                        className="form-control input-theme"
                        onChange={(e) => setDob(e.target.value)}
                        value={dob}
                      />
                      {errors.dob && <div className="error">{errors.dob}</div>}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3 mb-lg-0">
                    <Label labelHeading="Gender*" />
                    <div className="select-input tickets">
                      <select
                        name="Gender"
                        id="Gender*"
                        className="form-select mb-0"
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                      >
                        <option value="">Select an option</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Non Binary</option>
                      </select>
                      {errors.gender && (
                        <div className="error">{errors.gender}</div>
                      )}
                    </div>
                  </div>
                  <AddressAutocomplete handleAddress={setAddressObject} />

                  <div className="col-lg-6 mb-3">
                    <Label labelHeading="Type*" />
                    <div className="select-input tickets">
                      <select
                        name="Type"
                        id="Type*"
                        className="form-select mb-0"
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                      >
                        <option value="">Select an option</option>
                        <option value="Instructor">Instructor</option>
                        <option value="Front Desk">Front Desk</option>
                        <option value="Manager">Manager</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <Label labelHeading="Skill level*" />
                    <div className="select-input tickets">
                      <select
                        name="Skill"
                        id="Skill"
                        className="form-select mb-0"
                        onChange={(e) => setSkillLevel(e.target.value)}
                        value={skillLevel}
                      >
                        <option value="">Select an option</option>
                        <option value="Novice/Never Ever">
                          Novice/Never Ever
                        </option>
                        <option value="Beginner/In-training">
                          Beginner/In-training
                        </option>
                        <option value="Proficient/Intermediate">
                          Proficient/Intermediate
                        </option>
                        <option value="Advanced/Specialist">
                          Advanced/Specialist
                        </option>
                        <option value="Professional">Professional</option>
                      </select>
                      {errors.skillLevel && (
                        <div className="error">{errors.skillLevel}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <Label labelHeading="Bio" />
                    <div className="tickets-2 pb-0">
                      <textarea
                        className="form-control input-theme"
                        rows="3"
                        onChange={(e) => setBio(e.target.value)}
                        value={bio}
                      ></textarea>
                      {errors.bio && <div className="error">{errors.bio}</div>}
                    </div>
                  </div>
                  <div className="col-12">
                    <Label labelHeading="Avatar" />
                    <ImageUploadInput setFile={setFile} />
                  </div>
                </div>
                {generalError && (
                  <p className="error text-center mt-3">{generalError}</p>
                )}
              </div>
              <div className="modal-footer border-0 justify-content-center">
                <ButtonLoader
                  active="orange-outline"
                  bgChange="white-bg w-pd-add save-w"
                  type="submit"
                  isLoading={isLoading}
                >
                  Save
                </ButtonLoader>

                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="close-modal"
                  onClick={handleReset}
                >
                  <ButtonRemoveImage
                    Heading="Close"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <DeleteModal
        selectedData={selectedData}
        handleUpdateList={fetchInstructorList}
      /> */}
    </>
  );
};

export default Staff;
