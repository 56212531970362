import { useEffect, useState } from 'react';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import { AXIS_CONFIG_ENDPOINT } from '../Utils/Endpoints';
import { USER_CLASS } from '../Utils/ModelClasses';
import { getLoginUser } from '../Utils/LocalStorage';
import LoadingOverlayMain from '../Components/LoadingOverlayMain';
import Label from '../Components/Label';
import ButtonLoader from '../Components/ButtonLoader';
import Toast from '../Utils/Toast';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import { Link } from 'react-router-dom';

const AxisConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [, setAxisConfig] = useState(null);
  const [formData, setFormData] = useState({
    axis_enabled: true,
    axis_url: '',
    axis_username: '',
    axis_password: '',
    axis_store_id: '',
    axis_token: '',
  });
  const [currentUser] = useState(getLoginUser());
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    fetchAxisConfig();
  }, []);

  const fetchAxisConfig = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        AXIS_CONFIG_ENDPOINT +
          `?owner_class=${USER_CLASS}&owner_id=${currentUser?.id}`
      );
      if (response.data.code === 200) {
        const responseData = response.data.data;
        setAxisConfig(responseData);
        // Create a new object with key-value pairs from the response
        const newFormData = {
          axis_enabled: responseData.AXIS?.form?.axis_enabled?.value || false,
          axis_url: responseData.AXIS?.form?.axis_url?.value || '',
          axis_username: responseData.AXIS?.form?.axis_username?.value || '',
          axis_password: responseData.AXIS?.form?.axis_password?.value || '',
          axis_store_id: responseData.AXIS?.form?.axis_store_id?.value || '',
          axis_token: responseData.AXIS?.form?.axis_token?.value || '',
        };

        setFormData(newFormData);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const enabled = formData.axis_enabled;

    if (enabled) {
      if (!formData.axis_url) newErrors.axis_url = 'URL is required';
      if (!formData.axis_username)
        newErrors.axis_username = 'Username is required';
      if (!formData.axis_password)
        newErrors.axis_password = 'Password is required';
      if (!formData.axis_store_id)
        newErrors.axis_store_id = 'Store ID is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      setIsLoading(true);
      // Extract key-value pairs from the form data
      const formValues = {
        axis_enabled: formData.axis_enabled,
        axis_url: formData.axis_url,
        axis_username: formData.axis_username,
        axis_password: formData.axis_password,
        axis_store_id: formData.axis_store_id,
      };

      const response = await axiosInstance.post(AXIS_CONFIG_ENDPOINT, {
        owner_class: USER_CLASS,
        owner_id: currentUser?.id,
        settings: {
          AXIS: formValues,
        },
      });
      if (response.data.code === 200) {
        const responseData = response.data.data;
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
        setAxisConfig(responseData);
        // Create a new object with key-value pairs from the response
        const newFormData = {
          axis_enabled: responseData.AXIS?.form?.axis_enabled?.value || false,
          axis_url: responseData.AXIS?.form?.axis_url?.value || '',
          axis_username: responseData.AXIS?.form?.axis_username?.value || '',
          axis_password: responseData.AXIS?.form?.axis_password?.value || '',
          axis_store_id: responseData.AXIS?.form?.axis_store_id?.value || '',
          axis_token: responseData.AXIS?.form?.axis_token?.value || '',
        };

        setFormData(newFormData);
        setIsEdit(false);
      }
    } catch (error) {
      Logger.error(error);
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  return (
    <>
      {isLoading && <LoadingOverlayMain />}
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">Axis Config</h3>
                </div>

                <form className="triangle-top" onSubmit={handleSubmit}>
                  <div className="body-text text-start">
                    <div className="row">
                      <div className="col-lg-6">
                        <Label labelHeading="Enabled" />
                        <div className="tickets">
                          <input
                            type="checkbox"
                            name="axis_enabled"
                            checked={formData.axis_enabled}
                            onChange={handleChange}
                            className="form-check-input"
                            placeholder=""
                            disabled={!isEdit}
                            readOnly
                          />
                          {errors.axis_enabled && (
                            <div className="error">{errors.axis_enabled}</div>
                          )}
                        </div>
                      </div>
                      {formData.axis_enabled && (
                        <>
                          <div className="col-lg-6">
                            <Label labelHeading="URL" />
                            <div className="tickets">
                              <input
                                type="text"
                                name="axis_url"
                                value={formData.axis_url}
                                onChange={handleChange}
                                className="form-control input-theme"
                                placeholder="https://xxx.azurewebsites.net"
                                disabled={!formData.axis_enabled || !isEdit}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Username" />
                            <div className="tickets">
                              <input
                                type="text"
                                name="axis_username"
                                value={formData.axis_username}
                                onChange={handleChange}
                                className="form-control input-theme"
                                placeholder="Enter username"
                                disabled={!formData.axis_enabled || !isEdit}
                                autoComplete="off"
                              />
                              {errors.axis_username && (
                                <div className="invalid-feedback">
                                  {errors.axis_username}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Password" />
                            <div className="tickets">
                              <input
                                type="password"
                                name="axis_password"
                                value={formData.axis_password}
                                onChange={handleChange}
                                className="form-control input-theme"
                                placeholder="Enter password"
                                disabled={!formData.axis_enabled || !isEdit}
                                autoComplete={'off'}
                              />
                              {errors.axis_password && (
                                <div className="invalid-feedback">
                                  {errors.axis_password}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Store ID" />
                            <div className="tickets">
                              <input
                                type="number"
                                name="axis_store_id"
                                value={formData.axis_store_id}
                                onChange={handleChange}
                                className="form-control input-theme"
                                placeholder="Enter store ID"
                                disabled={!formData.axis_enabled || !isEdit}
                              />
                              {errors.axis_store_id && (
                                <div className="invalid-feedback">
                                  {errors.axis_store_id}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Label labelHeading="Token" />
                            <div className="tickets">
                              <textarea
                                name="axis_token"
                                value={formData.axis_token}
                                className="form-control"
                                placeholder="Token will be generated after saving"
                                rows={7}
                                readOnly
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {generalError && (
                        <p className="error text-center mt-3">{generalError}</p>
                      )}

                      <div className="set-button mt-5">
                        {isEdit ? (
                          <>
                            <ButtonLoader
                              type="submit"
                              bgChange={`white-bg w-pd-add save-w`}
                              active="orange-outline"
                              isLoading={isLoading}
                            >
                              Save
                            </ButtonLoader>
                            <div onClick={() => setIsEdit(false)}>
                              <ButtonRemoveImage
                                Heading="Cancel"
                                active="gary-light-outline"
                                bgChange="gary-bg w-pd-add"
                              />
                            </div>
                          </>
                        ) : (
                          <Link to="#" onClick={handleEdit} className="d-none">
                            <ButtonRemoveImage
                              Heading="Edit"
                              active="gary-light-outline"
                              bgChange="gary-bg w-pd-add"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AxisConfig;
