import React, { useEffect, useState } from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import Label from '../Components/Label';
import PropTypes from 'prop-types';
// import ImageUploadInput from './ImageUploadInput';
import { getItem } from '../Utils/LocalStorage';
import * as Yup from 'yup';
import { uploadFile } from '../Utils/upload';
import { ADD_BAY_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import Toast from '../Utils/Toast';
import ButtonLoader from './ButtonLoader';
import { BAY_CLASS } from '../Utils/ModelClasses';
import { BAY_BANNER_TYPE } from '../Utils/UploadType';

// Validation schema
const ManageBaySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  memberReservationCost: Yup.string().required('Required'),
  rangeReservationCost: Yup.string().required('Required'),
  slotDuration: Yup.string().required('Required'),
  rangeReservationDayOut: Yup.string().required('Required'),
  memberBaseReservationDayOut: Yup.string().required('Required'),
});

const ModalLaneInput = ({
  headingBay,
  selectedBay,
  setSelectedBay,
  handleUpdateList,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [memberReservationCost, setMemberReservationCost] = useState();
  const [rangeReservationCost, setRangeReservationCost] = useState();
  const [isReservationActive, setIsReservationActive] = useState(false);
  const [slotDuration, setSlotDuration] = useState();
  const [allowLaneSelection, setAllowLaneSelection] = useState(false);
  const [rangeReservationDayOut, setRangeReservationDayOut] = useState();
  const [memberBaseReservationDayOut, setMemberBaseReservationDayOut] =
    useState();
  const [bayID, setBayID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedLanes, setSelectedLanes] = useState(1);

  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const rangeId = getItem('rangeId');

  const validate = () => {
    try {
      ManageBaySchema.validateSync(
        {
          name,
          description,
          memberReservationCost,
          rangeReservationCost,
          slotDuration,
          rangeReservationDayOut,
          memberBaseReservationDayOut,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseBayId;
      if (!bayID) {
        const response = await axiosInstance.post(ADD_BAY_ENDPOINT + rangeId, {
          name: name,
          description: description,
          is_reservation_active: isReservationActive ? 1 : 0,
          base_reservation_cost: rangeReservationCost,
          member_reservation_cost: memberReservationCost,
          slot_duration: slotDuration, //integer | in Minutes
          allow_lane_selection: allowLaneSelection, //boolean,
          base_reservation_day_out: rangeReservationDayOut, //integer | In days
          member_base_reservation_day_out: memberBaseReservationDayOut, //, //integer | In days
        });

        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseBayId = response.data.data.id;
        setBayID(response.data.data.id);
      }

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            BAY_CLASS,
            responseBayId ? responseBayId : bayID,
            BAY_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      handleReset();
      const modalClose = document.getElementsByClassName('close-bay-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      await handleUpdateList();
      Toast.success('Bay successfully created.', {
        // onClose: () => {
        //   navigate('/settings/range-locations');
        // },
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseBayId;
      if (!bayID) {
        const response = await axiosInstance.patch(
          ADD_BAY_ENDPOINT + selectedBay?.id + '/' + rangeId,
          {
            name: name,
            description: description,
            is_reservation_active: isReservationActive ? 1 : 0,
            base_reservation_cost: rangeReservationCost,
            member_reservation_cost: memberReservationCost,
            slot_duration: slotDuration, //integer | in Minutes
            allow_lane_selection: allowLaneSelection, //boolean,
            base_reservation_day_out: rangeReservationDayOut, //integer | In days
            member_base_reservation_day_out: memberBaseReservationDayOut, //, //integer | In days
          }
        );

        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseBayId = response.data.data.id;
        setBayID(response.data.data.id);
      }

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            BAY_CLASS,
            responseBayId ? responseBayId : bayID,
            BAY_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      handleReset();
      const modalClose = document.getElementsByClassName('close-bay-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      await handleUpdateList();

      Toast.success('Bay successfully updated.', {
        // onClose: () => {
        //   navigate('/settings/range-locations');
        // },
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setName('');
    setDescription('');
    setAllowLaneSelection(false);
    setMemberReservationCost('');
    setRangeReservationCost('');
    setIsReservationActive(false);
    setSlotDuration('');
    setRangeReservationDayOut('');
    setMemberBaseReservationDayOut('');
    setGeneralError('');
    setErrors({});
    setBayID(null);
    setImageID(null);
    setFile(null);
    if (selectedBay?.id) {
      setSelectedBay({});
    }
  };

  useEffect(() => {
    if (selectedBay?.id) {
      setName(selectedBay?.name);
      setDescription(selectedBay?.description);
      setAllowLaneSelection(selectedBay?.allow_lane_selection);
      setMemberReservationCost(selectedBay?.member_reservation_cost);
      setRangeReservationCost(selectedBay?.base_reservation_cost);
      setIsReservationActive(selectedBay?.is_reservation_active);
      setSlotDuration(selectedBay?.slot_duration);
      setRangeReservationDayOut(selectedBay?.base_reservation_day_out);
      setMemberBaseReservationDayOut(
        selectedBay?.member_base_reservation_day_out
      );
    }
  }, [selectedBay]);

  // Array of options for the number of lanes
  const laneOptions = Array.from({ length: 10 }, (_, index) => index + 1);

  return (
    <>
      <div className="modal fade" id="modalLane" data-bs-backdrop="static">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="lane-name">{headingBay}</h4>
            </div>
            <form onSubmit={selectedBay?.id ? handleUpdate : handleSubmit}>
              <div className="modal-body modal-height">
                <div className="row">
                  <div className="col-lg-6">
                    <Label labelHeading="Name:" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      {errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3 mt-lg-0">
                    <Label labelHeading="Description:" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                      {errors.description && (
                        <div className="error">{errors.description}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <Label labelHeading="Guest Reservation Cost" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        placeholder="$"
                        onChange={(e) =>
                          setRangeReservationCost(e.target.value)
                        }
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={rangeReservationCost}
                      />
                      {errors.rangeReservationCost && (
                        <div className="error">
                          {errors.rangeReservationCost}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <Label labelHeading="Members Reservation Cost" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        placeholder="$"
                        onChange={(e) =>
                          setMemberReservationCost(e.target.value)
                        }
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={memberReservationCost}
                      />
                      {errors.memberReservationCost && (
                        <div className="error">
                          {errors.memberReservationCost}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <Label labelHeading="Guest Reservation Day Out" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        className="form-control input-theme mb-0"
                        onChange={(e) =>
                          setRangeReservationDayOut(e.target.value)
                        }
                        value={rangeReservationDayOut}
                      />
                      {errors.rangeReservationDayOut && (
                        <div className="error">
                          {errors.rangeReservationDayOut}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <Label labelHeading="Members Reservation Day Out" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        onChange={(e) =>
                          setMemberBaseReservationDayOut(e.target.value)
                        }
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={memberBaseReservationDayOut}
                      />
                      {errors.memberBaseReservationDayOut && (
                        <div className="error">
                          {errors.memberBaseReservationDayOut}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-6 mt-3">
                  <Label labelHeading="Status:" />
                  <div className="select-input tickets">
                    <select
                      name="status"
                      id="status"
                      className="form-select mb-0"
                    >
                      <option value="Active">Active</option>
                      <option value="India">Inactive</option>
                    </select>
                  </div>
                </div> */}
                  <div className="col-lg-6 mt-3">
                    <Label labelHeading="Session Duration" />
                    <div className="select-input tickets">
                      <select
                        name="time"
                        id="time"
                        className="form-select mb-0"
                        value={slotDuration}
                        onChange={(e) => setSlotDuration(e.target.value)}
                      >
                        <option value="">Select Slot Duration</option>
                        <option value={30}>30 Minutes</option>
                        <option value={45}>45 Minutes</option>
                        <option value={60}>60 Minutes</option>
                        <option value={120}>120 Minutes</option>
                      </select>
                      {errors.slotDuration && (
                        <div className="error">{errors.slotDuration}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <Label labelHeading="Select Number of Lanes" />
                    <div className="select-input tickets">
                      <select
                        name="time"
                        id="time"
                        className="form-select mb-0"
                        value={selectedLanes}
                        onChange={(e) => setSelectedLanes(e.target.value)}
                      >
                        {laneOptions.map((lane) => (
                          <option key={lane} value={lane}>
                            {lane}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <Label labelHeading="Online Reservations" />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="active"
                        onChange={(e) =>
                          setIsReservationActive(e.target.checked)
                        }
                        checked={isReservationActive}
                      />
                      <label className="form-check-label" htmlFor="active">
                        Active
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <Label labelHeading="Lane Selection" />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="active"
                        onChange={(e) =>
                          setAllowLaneSelection(e.target.checked)
                        }
                        checked={allowLaneSelection}
                      />
                      <label className="form-check-label" htmlFor="active">
                        Active
                      </label>
                    </div>
                  </div>

                  {/* <div className="col-lg-12 mb-3 mt-3">
                    <Label labelHeading="Logo:" />
                    <ImageUploadInput setFile={setFile} />
                  </div> */}
                </div>
                {generalError && (
                  <p className="error text-center mt-3">{generalError}</p>
                )}
              </div>
              <div className="modal-footer border-0 justify-content-center">
                <ButtonLoader
                  isLoading={isLoading}
                  type="submit"
                  active="orange-outline"
                  bgChange="bg-orange w-pd-add save-w"
                >
                  {selectedBay?.id ? 'Update' : 'Save'}
                </ButtonLoader>

                <Link
                  data-bs-dismiss="modal"
                  className="close-bay-modal"
                  onClick={handleReset}
                >
                  <ButtonRemoveImage
                    Heading="Cancel"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

ModalLaneInput.propTypes = {
  headingBay: PropTypes.string,
  selectedBay: PropTypes.object,
  setSelectedBay: PropTypes.func,
  handleUpdateList: PropTypes.func,
};

export default ModalLaneInput;
