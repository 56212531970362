// AddressAutocomplete.tsx
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import Label from './Label';
import Logger from '../Utils/Logger';
import axiosInstance from '../Utils/axiosInstance';
import { COUNTRIES_ENDPOINT, STATES_ENDPOINT } from '../Utils/Endpoints';
import PropTypes from 'prop-types';
import Toast from '../Utils/Toast';

const AddressAutocomplete = ({ handleAddress, addressList }) => {
  const libraries = useMemo(() => ['places'], []);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    version: '3.31',
  });
  const inputRef = useRef();
  const [address, setAddress] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [requestObject, setRequestObject] = useState({});

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (addressList?.id) {
      setAddress(addressList.addressString);
      setSelectedCountry(addressList.country.id);
    } else {
      setAddress('');
      countryList?.map((item) => {
        if (item.is_default) {
          setSelectedCountry(item.id);
        }
      });
    }
  }, [addressList]);

  if (loadError) {
    Toast.error(loadError);
  }
  const fetchCountryList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(COUNTRIES_ENDPOINT);
      if (response.data.code === 200) {
        const dataRes = response?.data?.data;
        dataRes?.map((item) => {
          if (item.is_default) {
            setSelectedCountry(item.id);
          }
        });
        setCountryList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStateList = async (state) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        STATES_ENDPOINT + `/${Number(selectedCountry)}?search=${state}`
      );
      if (response.data.code === 200) {
        const dataRes = response?.data?.data;
        return dataRes[0];
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlaceChanged = async () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      const addressComponents = place.address_components;

      let city = '';
      let state = '';
      let postalCode = '';

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes('locality')) {
          city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          state = component.short_name;
        } else if (types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      }
      // Construct the complete address
      const completeAddress = `${place.formatted_address}, ${city}, ${state} ${postalCode}`;
      // Update the input field value
      setAddress(completeAddress);
      const stateData = await fetchStateList(state);
      const addressObject = {
        address_1: place?.name,
        address_2: null,
        city: city,
        state: state,
        country_id: Number(selectedCountry),
        zip_code: postalCode,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        is_billing: 0,
        is_shipping: 0,
        state_id: stateData?.id,
      };
      setRequestObject(addressObject);
      handleAddress(addressObject);
    }
  };

  const handleChangeInput = (e) => {
    setAddress(e.target.value);
  };

  const handleCountry = (e) => {
    setSelectedCountry(e.target.value);
    const request = { ...requestObject };
    request.country_id = Number(e.target.value);
    handleAddress(request);
  };

  return (
    isLoaded && (
      <>
        <div className="col-lg-6">
          <Label labelHeading="Address:" />
          <div className="tickets">
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              <input
                type="text"
                placeholder=""
                className="form-control input-theme"
                onChange={handleChangeInput}
                value={address}
                required
              />
            </StandaloneSearchBox>
          </div>
        </div>
        <div className="col-lg-6">
          <Label labelHeading="Country:" />
          <div className="select-input tickets">
            <select
              name="links"
              id="links"
              className="form-select "
              disabled={isLoading || countryList.length === 0}
              value={selectedCountry}
              onChange={handleCountry}
              required
            >
              <option value="">Select an option</option>
              {countryList?.length > 0 &&
                countryList?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </>
    )
  );
};

AddressAutocomplete.propTypes = {
  handleAddress: PropTypes.func,
  addressList: PropTypes.object,
};
export default AddressAutocomplete;
