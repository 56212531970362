import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Label from '../Components/Label';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import ImageUploadInput from '../Components/ImageUploadInput';
import ButtonAdd from '../Components/ButtonAdd';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import {
  BRAND_LIST_ENDPOINT,
  CATEGORY_LIST_ENDPOINT,
  PRODUCT_ENDPOINT,
  REMOVE_ENDPOINT,
} from '../Utils/Endpoints';
import * as Yup from 'yup';
import RichTextEditor from '../Components/RichTextEditor';
import Toast from '../Utils/Toast';
import { uploadFile } from '../Utils/upload';
import { PRODUCT_CLASS } from '../Utils/ModelClasses';
import { PRODUCT_BANNER_TYPE } from '../Utils/UploadType';
import ButtonLoader from '../Components/ButtonLoader';
import Multiselect from 'multiselect-react-dropdown';
import { getItem } from '../Utils/LocalStorage';

// Validation schema
const ProductRangeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  sku: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  basePrice: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === '') return null;
      return value;
    })
    .nullable()
    .required('Required')
    .moreThan(0, 'Price must be greater than 0'),
});

const AddForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sku, setSku] = useState('');
  const [type, setType] = useState('');
  const [course, setCourse] = useState('');
  const [basePrice, setBasePrice] = useState(0.0);
  const [sellingPrice, setSellingPrice] = useState(0.0);
  const [stock, setStock] = useState(0);
  const [model, setModel] = useState('');
  const [brandId, setBrandId] = useState(null);
  const [length, setLength] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [dimensionUnit, setDimensionUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [minOrderQty, setMinOrderQty] = useState(1);
  const [isActive, setIsActive] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [productID, setProductID] = useState(null);
  const [imageID, setImageID] = useState(null);
  const [file, setFile] = useState(null);
  const rangeId = getItem('rangeId');
  const navigate = useNavigate();
  const location = useLocation();
  const [imageData, setImageData] = useState({});

  const selectedProduct = location.state && location.state.product;

  useEffect(() => {
    if (selectedProduct?.seller_products[0]?.id) {
      setProductID(selectedProduct?.seller_products[0]?.id);
      setName(selectedProduct?.seller_products[0]?.name);
      setImageData(selectedProduct?.seller_products[0]?.banner);
      setSelectedCategory(
        selectedProduct?.entity_has_categories.map((item) => item.category)
      );
      setIsFeatured(selectedProduct?.seller_products[0]?.is_featured);
      setIsActive(selectedProduct?.seller_products[0]?.is_active);
      setMinOrderQty(
        selectedProduct?.seller_products[0]?.min_order_qty !== null
          ? selectedProduct?.seller_products[0]?.min_order_qty
          : 1
      );
      setWeightUnit(selectedProduct?.weight_unit);
      setDimensionUnit(selectedProduct?.dimension_unit);
      setWeight(selectedProduct?.weight);
      setHeight(selectedProduct?.height);
      setWidth(selectedProduct?.width);
      setLength(selectedProduct?.length);
      setBrandId(selectedProduct?.brand_id);
      setModel(selectedProduct?.model);
      setStock(
        selectedProduct?.seller_products[0]?.stock !== null
          ? selectedProduct?.seller_products[0]?.stock
          : 0
      );
      setSellingPrice(selectedProduct?.min_selling_price);
      setBasePrice(selectedProduct?.seller_products[0]?.base_price);
      setType(selectedProduct?.type);
      setCourse(selectedProduct?.seller_products[0]?.course_id);
      setSku(selectedProduct?.seller_products[0]?.sku);
      setDescription(
        selectedProduct?.seller_products[0]?.description !== null
          ? selectedProduct?.seller_products[0]?.description
          : ''
      );
    }
  }, [selectedProduct]);

  const validate = () => {
    try {
      ProductRangeSchema.validateSync(
        {
          name,
          description,
          sku,
          type,
          basePrice,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  useEffect(() => {
    fetchCategoryList();
    fetchBrandList();
  }, []);

  const fetchCategoryList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        CATEGORY_LIST_ENDPOINT + '?type=PRODUCT'
      );
      if (response.data.code === 200) {
        setCategory(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBrandList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(BRAND_LIST_ENDPOINT);
      if (response.data.code === 200) {
        setBrand(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseProductID;

      if (!productID) {
        const filteredArray = selectedCategory.map((item) => item.id);
        const response = await axiosInstance.post(PRODUCT_ENDPOINT + rangeId, {
          name: name,
          description: description,
          sku: sku,
          type: type,
          base_price: basePrice ? parseFloat(basePrice) : basePrice,
          selling_price: sellingPrice ? parseFloat(sellingPrice) : sellingPrice,
          model: model,
          brand_id: brandId ? parseFloat(brandId) : null,
          length: length ? parseFloat(length) : null,
          width: width ? parseFloat(width) : null,
          height: height ? parseFloat(height) : null,
          weight: weight ? parseFloat(weight) : null,
          dimension_unit: dimensionUnit,
          weight_unit: weightUnit,
          min_order_qty: parseFloat(minOrderQty),
          stock: parseFloat(stock),
          is_active: isActive ? 1 : 0,
          is_featured: isFeatured ? 1 : 0,
          categories: filteredArray,
          course_id: course ? parseInt(course) : course,
        });
        if (response.data.code !== 200) {
          setGeneralError(response.data.message);
          return;
        }
        responseProductID = response.data.data.id;

        setProductID(response.data.data.id);
      }

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            PRODUCT_CLASS,
            responseProductID ? responseProductID : productID,
            PRODUCT_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      Toast.success('Product created.', {
        onClose: () => {
          navigate('/store/products');
          handleReset();
        },
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      let responseProductID;

      const filteredArray = selectedCategory.map((item) => item.id);
      const response = await axiosInstance.patch(
        PRODUCT_ENDPOINT +
          selectedProduct?.seller_products[0]?.id +
          `/${rangeId}`,
        {
          name: name,
          description: description,
          sku: sku,
          type: type,
          base_price: basePrice ? parseFloat(basePrice) : basePrice,
          selling_price: sellingPrice ? parseFloat(sellingPrice) : sellingPrice,
          model: model,
          brand_id: brandId ? parseFloat(brandId) : null,
          length: length ? parseFloat(length) : null,
          width: width ? parseFloat(width) : null,
          height: height ? parseFloat(height) : null,
          weight: weight ? parseFloat(weight) : null,
          dimension_unit: dimensionUnit,
          weight_unit: weightUnit,
          min_order_qty: parseFloat(minOrderQty),
          stock: parseFloat(stock),
          is_active: isActive ? 1 : 0,
          is_featured: isFeatured ? 1 : 0,
          categories: filteredArray,
          course_id: course ? parseInt(course) : course,
        }
      );
      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      responseProductID = response.data.data.id;

      setProductID(response.data.data.id);

      if (file && !imageID) {
        try {
          const imageData = await uploadFile(
            PRODUCT_CLASS,
            responseProductID ? responseProductID : productID,
            PRODUCT_BANNER_TYPE,
            file
          );
          setImageID(imageData.data.data.id);
        } catch (error) {
          setGeneralError(error.response?.data?.message);
          return;
        }
      }

      Toast.success(
        response?.data?.message
          ? response?.data?.message
          : 'Product successfully created.',
        {
          onClose: () => {
            navigate('/store/products');
            handleReset();
          },
          autoClose: 2000,
        }
      );
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setName('');
    setFile(null);
    setImageID(null);
    setErrors({});
    setGeneralError('');
    setProductID(null);
    setSelectedCategory([]);
    setIsFeatured(false);
    setIsActive(false);
    setMinOrderQty(1);
    setWeightUnit(null);
    setDimensionUnit(null);
    setWeight(null);
    setHeight(null);
    setWidth(null);
    setLength(null);
    setBrandId(null);
    setModel('');
    setStock(0);
    setSellingPrice(0.0);
    setBasePrice(0.0);
    setType('');
    setSku('');
    setDescription('');
    setName('');
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await axiosInstance.delete(REMOVE_ENDPOINT + id);

      if (response.status === 204) {
        Toast.success('Deleted successfully.', {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    }
  };

  return (
    <>
      <div className="outer-border outer-over-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set pt-4 pt-md-1">
                  <div className="back-listing-tab">
                    <Link to="/store/products" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2 h-set-2">
                      {selectedProduct?.id ? 'Update' : 'New '} Product
                    </h3>
                  </div>
                  <div className="all-date-selected mt-3  new-back-btn-add">
                    <Link to="/store/products" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                  </div>
                </div>
                <form
                  className="triangle-top"
                  onSubmit={selectedProduct?.id ? handleUpdate : handleSubmit}
                >
                  <div className="body-text text-start add-pro">
                    <div className="row">
                      <div className="col-md-4">
                        <Label labelHeading="Product Name" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            placeholder=""
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                          {errors.name && (
                            <div className="error">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="SKU" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            onChange={(e) => setSku(e.target.value)}
                            value={sku}
                          />
                          {errors.sku && (
                            <div className="error">{errors.sku}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Type" />
                        <div className="tickets">
                          <select
                            id="inputState"
                            className="form-select"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                          >
                            <option selected value="">
                              Select.....
                            </option>
                            <option value="SIMPLE">SIMPLE</option>
                            <option value="VARIABLE" disabled>
                              VARIABLE
                            </option>
                            <option value="DIGITAL" disabled>
                              DIGITAL
                            </option>
                          </select>
                          {errors.type && (
                            <div className="error">{errors.type}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Product Regular Price" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            placeholder="$"
                            onChange={(e) => setBasePrice(e.target.value)}
                            value={basePrice}
                          />
                          {errors.basePrice && (
                            <div className="error">{errors.basePrice}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Product Sale Price" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            placeholder="$"
                            onChange={(e) => setSellingPrice(e.target.value)}
                            value={sellingPrice}
                          />
                          {errors.sellingPrice && (
                            <div className="error">{errors.sellingPrice}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Stock" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            placeholder=""
                            onChange={(e) => setStock(e.target.value)}
                            value={stock}
                          />
                          {errors.stock && (
                            <div className="error">{errors.stock}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Model" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            onChange={(e) => setModel(e.target.value)}
                            value={model}
                          />
                          {errors.model && (
                            <div className="error">{errors.model}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Brand Id" />
                        <div className="tickets">
                          <select
                            id="inputState"
                            className="form-select"
                            onChange={(e) => setBrandId(e.target.value)}
                            value={brandId}
                          >
                            <option selected value="">
                              Select.....
                            </option>
                            {brand?.map((item) => (
                              <option
                                key={item.id}
                                value={item.id}
                                disabled={item.is_active === 0 ? true : false}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {errors.brandId && (
                            <div className="error">{errors.brandId}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Length" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setLength(e.target.value)}
                            value={length}
                          />
                          {errors.length && (
                            <div className="error">{errors.length}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Width" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setWidth(e.target.value)}
                            value={width}
                          />
                          {errors.width && (
                            <div className="error">{errors.width}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Height" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setHeight(e.target.value)}
                            value={height}
                          />
                          {errors.height && (
                            <div className="error">{errors.height}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Weight" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setWeight(e.target.value)}
                            value={weight}
                          />
                          {errors.weight && (
                            <div className="error">{errors.weight}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Dimension Unit" />
                        <div className="tickets">
                          <select
                            id="inputState"
                            className="form-select"
                            onChange={(e) => setDimensionUnit(e.target.value)}
                            value={dimensionUnit}
                          >
                            <option selected>Select.... </option>
                            <option value="M">M</option>
                            <option value="CM">CM</option>
                            <option value="IN">IN</option>
                          </select>
                          {errors.dimensionUnit && (
                            <div className="error">{errors.dimensionUnit}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Weight Unit" />
                        <div className="tickets">
                          <select
                            id="inputState"
                            className="form-select"
                            onChange={(e) => setWeightUnit(e.target.value)}
                            value={weightUnit}
                          >
                            <option selected>Select.... </option>
                            <option value="LB">LB</option>
                          </select>
                          {errors.weightUnit && (
                            <div className="error">{errors.weightUnit}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Label labelHeading="Min Order Qty" />
                        <div className="tickets">
                          <input
                            type="number"
                            className="form-control input-theme"
                            onChange={(e) => setMinOrderQty(e.target.value)}
                            value={minOrderQty}
                          />
                          {errors.minOrderQty && (
                            <div className="error">{errors.minOrderQty}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Label labelHeading="Product Description" />
                        <div className="tickets-2">
                          <RichTextEditor
                            value={description}
                            setEditorData={setDescription}
                            index="description"
                          />
                          {errors.description && (
                            <div className="error">{errors.description}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Label labelHeading="Category" />
                        <div className="tickets">
                          <Multiselect
                            isObject={true}
                            options={category}
                            selectedValues={selectedCategory}
                            showCheckbox
                            onSelect={(option) => setSelectedCategory(option)}
                            onRemove={(option) => setSelectedCategory(option)}
                            displayValue="name"
                            customDisplay={({ name, is_active }) => (
                              <span
                                style={{
                                  color: is_active === 0 ? 'gray' : 'black',
                                  pointerEvents:
                                    is_active === 0 ? 'none' : 'auto',
                                }}
                              >
                                {name}
                              </span>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="gridCheck"
                          >
                            Active
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3 mb-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheckone"
                            checked={isFeatured}
                            onChange={(e) => setIsFeatured(e.target.checked)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="gridCheckone"
                          >
                            Featured
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <ImageUploadInput
                          setFile={setFile}
                          handleDeleteImage={handleDeleteImage}
                          imageData={imageData}
                        />
                      </div>
                      {generalError && (
                        <p className="error text-center mt-3">{generalError}</p>
                      )}
                      <div className="set-button">
                        <ButtonLoader
                          type="submit"
                          bgChange={`white-bg w-pd-add save-w`}
                          active="orange-outline"
                          isLoading={isLoading}
                        >
                          {selectedProduct?.id ? 'Update' : 'Save'}
                        </ButtonLoader>
                        {/* <ButtonRemoveImage
                          Heading="SAVE"
                          active="orange-outline"
                          bgChange="white-bg w-pd-add"
                        /> */}
                        <Link to="/store/products">
                          <ButtonRemoveImage
                            Heading="CANCEL"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddForm;
