import React from 'react';
import UserProcessing from '../Components/UserProcessing';
import { useNavigate } from 'react-router';

const Today = () => {
  const navigate = useNavigate();

  const classes = [
    {
      title: 'Handgun 101',
      time: '1PM-2PM CST',
      location: 'Stoddard’s - Midtown',
      instructor: 'Mitch O.',
    },
    {
      title: 'Rifle 101',
      time: '2PM-3PM CST',
      location: 'Stoddard’s - Midtown',
      instructor: 'Mitch O.',
    },
    {
      title: 'Handgun 101',
      time: '1PM-2PM CST',
      location: 'Stoddard’s - Midtown',
      instructor: 'Mitch O.',
    },
    {
      title: 'Rifle 101',
      time: '4PM-5PM CST',
      location: 'Stoddard’s - Midtown',
      instructor: 'Mitch O.',
    },
  ];

  const privateTrainingSessions = [
    {
      title: 'Handgun 101',
      time: '1PM-2PM CST',
      location: 'Stoddard’s - Midtown',
      instructor: 'Mitch O.',
    },
    {
      title: 'Rifle 101',
      time: '2PM-3PM CST',
      location: 'Stoddard’s - Midtown',
      instructor: 'Mitch O.',
    },
  ];

  return (
    <>
      <div className="outer-border outer-overflow-show">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="card-body">
                <h3>Classes & Events</h3>
                <div className="triangle-top">
                  <div className="body-text body-listing body-listing scrolling">
                    <ul>
                      {classes.map((classItem, index) => (
                        <li
                          key={index}
                          className="cursor-p"
                          onClick={() => navigate('/new/class')}
                        >
                          <h4>
                            {classItem.title} <span>{classItem.time}</span>
                          </h4>
                          <p>
                            {classItem.location}{' '}
                            <span>{classItem.instructor}</span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card-body">
                <h3>PRIVATE TRAINING</h3>
                <div className="triangle-top">
                  <div className="body-text  body-listing body-listing scrolling">
                    <ul>
                      {privateTrainingSessions.map((session, index) => (
                        <li
                          key={index}
                          className="cursor-p"
                          onClick={() => navigate('/settings/instructor')}
                        >
                          <h4>
                            {session.title} <span>{session.time}</span>
                          </h4>
                          <p>
                            {session.location} <span>{session.instructor}</span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="progress-list-user triangle-card ling-set">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <h3>ON-RANGE OVERVIEW</h3>
                  </div>
                  <div className="col-md-8">
                    <div className="all-user">
                      <UserProcessing
                        imagesCard="../assets/icon/boking_calendar.svg"
                        userHeading="Bookings & Reservation"
                        userTitle="25"
                      />
                      <UserProcessing
                        imagesCard="../assets/icon/new_guests.svg"
                        userHeading="New Guests"
                        userTitle="11"
                      />
                      <UserProcessing
                        imagesCard="../assets/icon/bays_available.svg"
                        userHeading="Bays Available "
                        userTitle="3"
                      />
                      <UserProcessing
                        imagesCard="../assets/icon/lane_available.svg"
                        userHeading="Lanes Available"
                        userTitle="18"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Today;
