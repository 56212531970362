import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BOOKING_LIST_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import Pagination from '../Components/Pagination';
import moment from 'moment';
import { getItem } from '../Utils/LocalStorage';
import ButtonAdd from '../Components/ButtonAdd';

const Booking = () => {
  const [bookedList, setBookedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [search, setSearch] = useState('');

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rangeId = getItem('rangeId');

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchBookedList();
  }, [selectedDate, currentPage, search, itemsPerPage]);

  const fetchBookedList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        BOOKING_LIST_ENDPOINT +
          `/${rangeId}?order_item_type=LANE_BOOKING&page=${currentPage}&per_page=${itemsPerPage}&search=${search}&booking_from=${selectedDate}`
      );
      if (response.data.code === 200) {
        setTotalItems(response.data.data.total);
        setBookedList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="outer-border">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card-body">
              <div className="employees employ-set">
                <h3 className="card-top-2">New Booking</h3>
                <div className="all-date-selected">
                  <div className="date-input tickets">
                    <input
                      type="date"
                      className="form-control mb-0"
                      placeholder="dd-mm-yyyy"
                      required
                      onChange={(e) => setSelectedDate(e.target.value)}
                      value={selectedDate}
                    />
                  </div>
                  <div className="input-search-items tickets">
                    <input
                      type="search"
                      className="form-control input-theme mb-0"
                      placeholder="Search..."
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setCurrentPage(1);
                      }}
                      value={search}
                    />
                    <img src="../assets/icon/search_icon.svg" alt="" />
                  </div>
                  <Link to="/ranges/book-slot-user-add">
                    <ButtonAdd
                      Heading="ADD"
                      images="../assets/icon/add_user.svg"
                      active="orange-outline"
                      bgChange="white-bg"
                    />
                  </Link>
                  {/* <Link to="/settings/range-create">
                      <ButtonAdd
                        Heading="ADD"
                        images="../assets/icon/plaus_icon.svg"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link> */}
                </div>
              </div>
              <div className="triangle-top">
                <div className="body-text booking-ac">
                  {bookedList?.length === 0 && !isLoading && (
                    <RangeNotFound title="Booking" isInfo={true} />
                  )}
                  {isLoading && <SkeletonLoader />}
                  {bookedList?.length > 0 && !isLoading && (
                    <div className="table-responsive">
                      <table className="table table-time-gun-create mobile-responsive mobile pb-5">
                        <thead className="head-left">
                          <tr>
                            <th>Order Id</th>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>No of Participants</th>
                            <th>Total</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody className="table-time-gun-create__old-even text-start mobile__old-even">
                          {bookedList?.map((item, index) => (
                            <tr key={index}>
                              <td data-label="Order">
                                <ul className="mb-0 text-md-center">
                                  <li>#{item?.order_number}</li>
                                  <li>
                                    {item?.order_items &&
                                      item?.order_items[0]?.holder?.bay?.name}
                                    <br />
                                    {/* Guns – Atlanta Midtown */}
                                    <li>
                                      Lane{' - '}
                                      {item?.order_items &&
                                        item?.order_items[0]?.holder?.name}
                                    </li>
                                    {/* <li>
                                    Stoddard’s Range and <br />
                                    Guns – Atlanta Midtown
                                  </li> */}
                                  </li>
                                </ul>
                              </td>
                              <td data-label="Date ">
                                <p className="color-black table-button-width text-md-center">
                                  {moment(item?.created_at).format(
                                    'MMMM D, YYYY h:mm A'
                                  )}
                                </p>
                              </td>
                              <td data-label="Customer">
                                <div className="text-md-center">
                                  <p className="color-black">
                                    {item?.user?.name}
                                  </p>
                                  <Link
                                    to={`mailto:${item?.user?.email}`}
                                    className="color-gray"
                                  >
                                    {item?.user?.email}
                                  </Link>
                                  <p className="color-gray">
                                    {item?.user?.phone_number}
                                  </p>
                                </div>
                              </td>
                              <td data-label="No of Participants">
                                <p className="color-black text-md-center">
                                  {
                                    item?.order_items[0]?.bookings[0]
                                      ?.booking_participants?.length
                                  }
                                </p>
                              </td>
                              <td
                                data-label="Total"
                                align="center"
                                className="pt-4 pt-md-0"
                              >
                                <p className="color-black text-md-center">
                                  ${item?.transactions[0]?.amount.toFixed(2)}
                                </p>
                              </td>
                              <td data-label="Status">
                                <p className="color-black text-decoration-underline text-md-center center-left">
                                  {item?.order_status?.identifier}
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {bookedList?.length > 0 && !isLoading && (
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      setItemsPerPage={setItemsPerPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
