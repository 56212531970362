import React, { useEffect, useState } from 'react';
import UserCard from '../Components/UserCard';
import ButtonAdd from '../Components/ButtonAdd';
import { Link } from 'react-router-dom';
import { RANGE_TRAINERS_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import Logger from '../Utils/Logger';
import SkeletonLoader from '../Components/SkeletonLoader';
import RangeNotFound from '../Components/RangeNotFound';
import DeleteModal from '../Components/DeleteModal';
import { getItem } from '../Utils/LocalStorage';
import InstructorForm from '../Components/InstructorForm';

const Instructor = () => {
  const [instructorList, setInstructorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedData, setSelectedData] = useState({});
  const [selectedInstructor, setSelectedInstructor] = useState({});
  const rangeId = getItem('rangeId');

  useEffect(() => {
    fetchInstructorList();
  }, []);

  const fetchInstructorList = async () => {
    try {
      setIsLoading(true);
      // const response = await axiosInstance.get(
      //   INSTRUCTORS_ENDPOINT + '?per_page=100&order_by=id'
      // );
      const response = await axiosInstance.get(
        RANGE_TRAINERS_ENDPOINT + rangeId + '/list'
      );
      if (response.data.code === 200) {
        setInstructorList(response.data.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (id, type) => {
    setSelectedData({
      id,
      type,
    });
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <h3 className="card-top-2">INSTRUCTORS</h3>
                  <div className="all-date-selected">
                    <div className="input-search-items tickets">
                      <input
                        type="search"
                        className="form-control input-theme mb-0"
                        placeholder="Search..."
                      />
                      <img src="../assets/icon/search_icon.svg" alt="" />
                    </div>
                    <Link
                      to="#"
                      className="button-width"
                      data-bs-toggle="modal"
                      data-bs-target="#modalLane2"
                    >
                      <ButtonAdd
                        Heading="ADD"
                        images="../assets/icon/plaus_icon.svg"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>

                <div className="triangle-top">
                  <div className="body-text ins-card">
                    {instructorList?.length === 0 && !isLoading && (
                      <RangeNotFound title="Instructors" />
                    )}
                    {isLoading && <SkeletonLoader />}
                    <div className=" card-gun-id">
                      {instructorList?.length > 0 &&
                        !isLoading &&
                        instructorList.map((instructor, index) => (
                          <UserCard
                            hexBg="bg-black"
                            hexOuterLine="bg-width-outline"
                            UserImages={
                              instructor?.user?.profile_picture
                                ? instructor?.user?.profile_picture?.fullUrl
                                : '../assets/images/profile.png'
                            }
                            Heading={instructor?.user.name}
                            SubHeading={'@' + instructor?.user?.username}
                            Text=""
                            Experience={
                              (instructor?.user?.role
                                ? instructor?.user?.role[
                                    instructor?.user?.role?.length - 1
                                  ]?.roleName
                                : 'INSTRUCTOR') +
                              ' | ' +
                              instructor?.user?.skill_level
                            }
                            key={index}
                            instructor={instructor}
                            handleDelete={handleDelete}
                            setSelectedInstructor={setSelectedInstructor}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InstructorForm
        fetchInstructorList={fetchInstructorList}
        selectedInstructor={selectedInstructor}
        setSelectedInstructor={setSelectedInstructor}
      />

      <DeleteModal
        selectedData={selectedData}
        handleUpdateList={fetchInstructorList}
      />
    </>
  );
};

export default Instructor;
