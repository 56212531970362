import React, { useEffect, useState } from 'react';
import ButtonAdd from '../Components/ButtonAdd';
import { Link } from 'react-router-dom';
import ModalLaneInput from '../Components/ModalLaneInput';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import Label from '../Components/Label';
import DeleteModal from '../Components/DeleteModal';
import axiosInstance from '../Utils/axiosInstance';
import RangeNotFound from '../Components/RangeNotFound';
import SkeletonLoader from '../Components/SkeletonLoader';
import Logger from '../Utils/Logger';
import { getItem, saveItem } from '../Utils/LocalStorage';
import { ADD_LANE_ENDPOINT, LIST_BAY_LANES_ENDPOINT } from '../Utils/Endpoints';
import * as Yup from 'yup';
import Toast from '../Utils/Toast';
import ButtonLoader from '../Components/ButtonLoader';

// Validation schema
const ManageLaneSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  isOpen: Yup.string().required('Required'),
});

const ManageLanes = () => {
  const [laneList, setLaneList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const bayData = getItem('bayData');

  // Create Lane
  const [name, setName] = useState('');
  const [memberReservationCost, setMemberReservationCost] = useState();
  const [rangeReservationCost, setRangeReservationCost] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});

  const [selectedLane, setSelectedLane] = useState({});
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    fetchLaneList();
  }, []);

  const fetchLaneList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        LIST_BAY_LANES_ENDPOINT + bayData?.id
      );
      if (response.data.code === 200) {
        setLaneList(response?.data?.data?.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = () => {
    try {
      ManageLaneSchema.validateSync(
        {
          name,
          isOpen,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(
        ADD_LANE_ENDPOINT + bayData?.id,
        {
          name: name, //string
          is_open: Number(isOpen), //, //boolean
        }
      );

      if (response.data.code === 200) {
        const modalClose = document.getElementsByClassName('close-lane-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        await fetchLaneList();
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.patch(
        ADD_LANE_ENDPOINT + `${selectedLane?.id}/` + bayData?.id,
        {
          name: name, //string
          is_open: Number(isOpen), //, //boolean
        }
      );
      if (response.data.code === 200) {
        const modalClose = document.getElementsByClassName('close-lane-modal');
        for (let i = 0; i < modalClose.length; i++) {
          modalClose[i].click();
        }
        await fetchLaneList();
        Toast.success(response.data.message, {
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setName('');
    setMemberReservationCost('');
    setRangeReservationCost('');
    setIsOpen(false);
    setGeneralError('');
    setErrors({});
    setSelectedLane({});
  };

  const handleEdit = (item) => {
    setSelectedLane(item);
    setName(item.name);
    setIsOpen(item.is_open);
  };

  const handleDelete = (id, type) => {
    setSelectedData({
      id,
      type,
    });
  };

  const handleViewAvailability = (laneId) => {
    saveItem('laneId', laneId);
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body ">
                <div className="employees employ-set pt-4 pt-md-1">
                  <div className="back-listing-tab">
                    <Link to="/ranges/manage" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2">MANAGE LANES</h3>
                  </div>
                  <div className="add-user-active">
                    <div className="booked">
                      <div className="book-img">
                        <img src="../assets/icon/add_user.svg" alt="" />
                      </div>
                      <p>
                        <span>Booked</span> 8
                      </p>
                    </div>
                    <div className="booked">
                      <div className="book-img book-bg-change">
                        <img src="../assets/icon/add_user.svg" alt="" />
                      </div>
                      <p>
                        <span>Available</span>5
                      </p>
                    </div>
                  </div>
                  <div className="all-date-selected mt-3  new-back-btn-add">
                    <Link to="/ranges/manage" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#modalLane2"
                      className="button-width"
                    >
                      <ButtonAdd
                        Heading="Add"
                        images="../assets/icon/plaus_icon.svg"
                        active="orange-outline"
                        bgChange="bgColor white-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>
                {/* <h3>MANAGE LANES</h3> */}
                <div className="triangle-top">
                  <div className="body-text pt-0">
                    <div className="lane-booking d-none">
                      <div className="change-bay">
                        <Link to="/ranges/manage">
                          <i
                            className="fa fa-angle-left"
                            aria-hidden="true"
                          ></i>
                          <span>Change Bay&#39;s</span>
                        </Link>
                      </div>
                      <div className="add-user-active">
                        <div className="booked">
                          <div className="book-img">
                            <img src="../assets/icon/add_user.svg" alt="" />
                          </div>
                          <p>
                            <span>Booked</span> 8
                          </p>
                        </div>
                        <div className="booked">
                          <div className="book-img book-bg-change">
                            <img src="../assets/icon/add_user.svg" alt="" />
                          </div>
                          <p>
                            <span>Available</span>5
                          </p>
                        </div>
                      </div>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLane2"
                        className="d-none"
                      >
                        <ButtonAdd
                          Heading="Add"
                          images="../assets/icon/plaus_icon.svg"
                          active="orange-outline"
                          bgChange="bgColor white-bg w-pd-add"
                        />
                      </Link>
                    </div>
                    <div className="create-box">
                      {laneList?.length > 0 &&
                        laneList?.map((item, index) => (
                          <Link
                            to={item.is_open ? '/ranges/availability' : '#'}
                            onClick={(event) => {
                              if (!item.is_open) {
                                event.preventDefault(); // Prevent redirect if not open
                              } else {
                                handleViewAvailability(item.id); // Call the function if open
                              }
                            }}
                            className="create-box__leans"
                            key={index}
                          >
                            <div className="card-icon">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#modalLane2"
                                onClick={() => handleEdit(item)}
                              >
                                <img src="../assets/icon/edit.svg" alt="" />
                              </Link>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                onClick={() =>
                                  handleDelete(item.id, 'manage_lane')
                                }
                              >
                                <img src="../assets/icon/delete.svg" alt="" />
                              </Link>
                            </div>
                            <div className={!item.is_open ? 'dis-aa' : ''}>
                              <h5 className="pb-0">{item.name}</h5>
                              <span
                                className={item.is_open ? 'o-f-s' : 'c-f-s'}
                              >
                                {item.is_open ? 'Open' : 'Close'}
                                {/* Closed for service */}
                              </span>
                            </div>
                          </Link>
                        ))}
                    </div>
                    {laneList?.length === 0 && !isLoading && (
                      <RangeNotFound title="Lanes" />
                    )}

                    {isLoading && (
                      <SkeletonLoader type="table" rows={6} columns={4} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLaneInput headingBay="Lane Name" />
        <DeleteModal
          selectedData={selectedData}
          handleUpdateList={fetchLaneList}
        />
      </div>

      <div className="modal fade" id="modalLane2" data-bs-backdrop="static">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="lane-name">
                {selectedLane?.id ? 'Update' : 'Create'} Lane
              </h4>
            </div>
            <form onSubmit={selectedLane?.id ? handleUpdate : handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <Label labelHeading="Name:" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      {errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6 mt-2">
                  <Label labelHeading="Number:" />
                  <div className="input-search tickets">
                    <input
                      type="text"
                      className="form-control input-theme mb-0"
                    />
                  </div>
                </div> */}

                  <div className="col-md-6 mt-2">
                    <Label labelHeading="Status:" />
                    <div className="select-input tickets">
                      <select
                        name="status"
                        id="status"
                        className="form-select mb-0"
                        onChange={(e) => setIsOpen(e.target.value)}
                        value={isOpen}
                      >
                        <option value="">Select Status</option>
                        <option value={1}>Open</option>
                        <option value={0}>Close</option>
                        <option value={0}>Closed for service</option>
                      </select>
                      {errors.isOpen && (
                        <div className="error">{errors.isOpen}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <Label labelHeading="Guest Reservation Cost" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        placeholder="$"
                        onChange={(e) =>
                          setRangeReservationCost(e.target.value)
                        }
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={rangeReservationCost}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <Label labelHeading="Members Reservation Cost" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        placeholder="$"
                        onChange={(e) =>
                          setMemberReservationCost(e.target.value)
                        }
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={memberReservationCost}
                      />
                      {errors.memberReservationCost && (
                        <div className="error">
                          {errors.memberReservationCost}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6 mt-2">
                  <Label labelHeading="Reservations:" />
                  <div className="select-input tickets">
                    <select
                      name="Reservations"
                      id="Reservations"
                      className="form-select mb-0"
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div> */}
                </div>
              </div>
              {generalError && (
                <p className="error text-center">{generalError}</p>
              )}
              <div className="modal-footer border-0 justify-content-center">
                <ButtonLoader
                  active="orange-outline"
                  bgChange="white-bg w-pd-add save-w"
                  type="submit"
                  isLoading={isLoading}
                >
                  {selectedLane?.id ? 'Update' : 'Save'}
                </ButtonLoader>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="close-lane-modal"
                  onClick={handleClose}
                >
                  <ButtonRemoveImage
                    Heading="Cancel"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageLanes;
