import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import MainLayout from './Layout/MainLayout';
import { publicRoutes } from './Routes/PublicRoutes';
import {
  accountRoutes,
  createNewRoutes,
  dashboardRoutes,
  rangeRoutes,
  settingsRoutes,
  upcomingRoutes,
} from './Routes/PrivateRoutes';
import NotFound from './Pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PublicRoute
                element={route.element}
                redirectTo={route.redirectTo}
              />
            }
          />
        ))}
        {/*--------- Dashboard -----*/}
        {dashboardRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <MainLayout>
                <PrivateRoute element={route.element} />
              </MainLayout>
            }
          />
        ))}
        {/*--------- Account -----*/}
        {accountRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <MainLayout>
                <PrivateRoute element={route.element} />
              </MainLayout>
            }
          />
        ))}
        {/*--------- Range -----*/}
        {rangeRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <MainLayout>
                <PrivateRoute element={route.element} />
              </MainLayout>
            }
          />
        ))}

        {/*---------  Create New  -----*/}
        {createNewRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <MainLayout>
                <PrivateRoute element={route.element} />
              </MainLayout>
            }
          />
        ))}

        {/*--------- Settings -----*/}
        {settingsRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <MainLayout>
                <PrivateRoute element={route.element} />
              </MainLayout>
            }
          />
        ))}

        {/*--------- Upcoming -----*/}
        {upcomingRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <MainLayout>
                <PrivateRoute element={route.element} />
              </MainLayout>
            }
          />
        ))}
        {/*--------- Not Found -----*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
