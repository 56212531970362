import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Logger from '../../Utils/Logger';
import ButtonRemoveImage from '../ButtonRemoveImage';
import { Link } from 'react-router-dom';
import ButtonLoader from '../ButtonLoader';
import PaymentService from '../../Utils/PaymentService';
import { LANE_CLASS } from '../../Utils/ModelClasses';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({
  // selectedBay,
  stripeData,
  selectedUsers,
  laneId,
  rangeId,
  selectedSlot,
  onSuccess,
  onError,
}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      const cardElement = elements.getElement(CardNumberElement);

      const { token } = await stripe.createToken(cardElement);

      if (error) {
        setIsError(true);
        setErrorMsg(error.message);
        setTimeout(() => {
          setIsError(false);
        }, 4000);
        setIsLoading(false);
        return;
      }

      // Prepare order data
      const orderData = {
        items: [
          {
            owner_class: LANE_CLASS,
            owner_id: laneId,
            quantity: 1,
            details: {
              participant_ids: selectedUsers[1] ? [selectedUsers[1].id] : [],
            },
          },
        ],
        user_id: selectedUsers[0]?.id,
        // payment_method_id: 1, // Stripe payment method ID
      };

      // Process payment using PaymentService
      await PaymentService.handlePayment({
        rangeId,
        orderData,
        paymentMethod: 'stripe',
        stripePaymentMethodId: paymentMethod.id,
        selectedSlot,
        onSuccess,
        onError,
        stripeData,
        cardId: token.card.id,
      });

      setIsLoading(false);
    } catch (error) {
      Logger.error('Error:', error);
      setIsError(true);
      setErrorMsg(error.message || 'Payment failed');
      setIsLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="payment-card-tm">
        <div className="card-payment-ab">
          <div className="row">
            <div className="col-12">
              <div className="tickets">
                <CardNumberElement className="form-control input-theme" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tickets">
                <CardExpiryElement className="form-control input-theme" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tickets">
                <CardCvcElement className="form-control input-theme" />
              </div>
            </div>
          </div>
        </div>
        <div className="pay-pay">
          <div className="button-range-create mt-5">
            <ButtonLoader
              Heading="Book"
              active="orange-outline"
              bgChange="white-bg w-pd-add save-w"
              type="submit"
              onClick={handleSubmit}
              disabled={!stripe || !elements ? true : false}
              isLoading={isLoading}
            >
              Book
            </ButtonLoader>
            <Link
              to="/ranges/lane-check-list"
              className="close-solcial-link-modal"
              data-bs-dismiss="modal"
            >
              <ButtonRemoveImage
                Heading="Cancel"
                active="gary-light-outline"
                bgChange="gary-bg w-pd-add"
              />
            </Link>
          </div>
        </div>
        {isError && (
          <h6 className="text-center text-danger d-md-block error-mag">
            {errorMsg}
          </h6>
        )}
      </div>
    </div>
  );
};

const StripePayment = ({
  stripeData,
  selectedBay,
  selectedUsers,
  laneId,
  rangeId,
  selectedSlot,
  onSuccess,
  onError,
}) => {
  console.log('StripePayment stripeData:', stripeData);
  const key = stripeData?.details?.test_mode
    ? stripeData?.details?.test_public_key
    : stripeData?.details?.public_key ||
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  console.log('Stripe key:', key);
  const stripePromise = loadStripe(key);

  return (
    <div className="row">
      <h4 className="cash-price">
        ${selectedBay?.base_reservation_cost?.toFixed(2) || selectedBay?.price}
      </h4>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          selectedBay={selectedBay}
          stripeData={stripeData}
          selectedUsers={selectedUsers}
          laneId={laneId}
          rangeId={rangeId}
          selectedSlot={selectedSlot}
          onSuccess={onSuccess}
          onError={onError}
        />
      </Elements>
    </div>
  );
};

// PropTypes for CheckoutForm
CheckoutForm.propTypes = {
  selectedBay: PropTypes.shape({
    id: PropTypes.number,
    base_reservation_cost: PropTypes.number,
    member_reservation_cost: PropTypes.number,
    start_unix_timestamp: PropTypes.number,
    end_unix_timestamp: PropTypes.number,
    price: PropTypes.number,
  }),
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayValue: PropTypes.string,
    })
  ).isRequired,
  laneId: PropTypes.string.isRequired,
  rangeId: PropTypes.string.isRequired,
  selectedSlot: PropTypes.shape({
    start_unix_timestamp: PropTypes.number.isRequired,
    end_unix_timestamp: PropTypes.number.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  stripeData: PropTypes.object,
};

// PropTypes for StripePayment
StripePayment.propTypes = {
  selectedBay: PropTypes.shape({
    id: PropTypes.number,
    base_reservation_cost: PropTypes.number,
    member_reservation_cost: PropTypes.number,
    start_unix_timestamp: PropTypes.number,
    end_unix_timestamp: PropTypes.number,
    price: PropTypes.number,
  }),
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayValue: PropTypes.string,
    })
  ).isRequired,
  laneId: PropTypes.string.isRequired,
  rangeId: PropTypes.string.isRequired,
  selectedSlot: PropTypes.shape({
    start_unix_timestamp: PropTypes.number.isRequired,
    end_unix_timestamp: PropTypes.number.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func,
  stripeData: PropTypes.object,
  onError: PropTypes.func,
};

export default StripePayment;
