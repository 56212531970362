import Account from '../Pages/Account';
import Availability from '../Pages/Availability';
import Booking from '../Pages/Booking';
import BookSlotUserAdd from '../Pages/BookSlotUserAdd';
import CreateQuestions from '../Pages/CreateQuestions';
import Experience from '../Pages/Experience';
import FirearmRentalOptions from '../Pages/FirearmRentalOptions';
import Guests from '../Pages/Guests';
import Index from '../Pages/Index';
import Instructor from '../Pages/Instructor';
import Manage from '../Pages/Manage';
import ManageLanes from '../Pages/ManageLanes';
import OrderDetails from '../Pages/OrderDetails';
import PlanIndividual from '../Pages/PlanIndividual';
import Plans from '../Pages/Plans';
import RangeActivities from '../Pages/RangeActivities';
import RangeLocations from '../Pages/RangeLocations';
import Today from '../Pages/Today';
import RangeDesk from '../Pages/RangeDesk';
import IdValidation from '../Pages/IdValidation';
import LaneInCheck from '../Pages/LaneInCheck';
import ClassCheckIn from '../Pages/ClassCheckIn';
import CreateRange from '../Pages/CreateRange';
import ValidationProcess from '../Pages/ValidationProcess';
import Participants from '../Pages/Participants';
import MembershipTwo from '../Pages/MembershipTwo';
import Orders from '../Pages/Orders';
import Products from '../Pages/Products';
import UserDetails from '../Pages/UserDetails';
import CpeValidationNew from '../Pages/CpeValidationNew';
import IdVerificationList from '../Pages/IdVerificationList';
import LaneCheckList from '../Pages/LaneCheckList';
import Class from '../Pages/Class';
import UpComing from '../Pages/UpComing';
import CreateRangeForm from '../Pages/CreateRangeForm';
import Events from '../Pages/Events';
import EventsNew from '../Pages/EventsNew';
import EventCategories from '../Pages/EventCategories';
import Attendees from '../Pages/Attendees';
import UserProfile from '../Pages/UserProfile';
import CreateClassForm from '../Pages/CreateClassForm';
import AddForm from '../Pages/AddForm';
import ProductOrderDetails from '../Pages/ProductOrderDetails';
import Receiptsnew from '../Pages/Receiptsnew';
import ClassDetails from '../Pages/ClassDetails';
import Staff from '../Pages/Staff';
import AxisConfig from '../Pages/AxisConfig';
import MembershipPurchase from '../Pages/MembershipPurchase';
// Dashboard Routes
export const dashboardRoutes = [
  // Dashboard
  { path: '/dashboard', element: <Index /> },
  // Today
  { path: '/today', element: <Today /> },

  // userprofile
  { path: '/user-profile', element: <UserProfile /> },

  // Not used route
  { path: '/range-desk', element: <RangeDesk /> },
  { path: '/id-validation', element: <IdValidation /> },
  { path: '/lane-in-check', element: <LaneInCheck /> },
  { path: '/class-check-in', element: <ClassCheckIn /> },
  { path: '/create-range', element: <CreateRange /> },
  { path: '/ranges/validation-process', element: <ValidationProcess /> },
  { path: '/ranges/firearm-rental-options', element: <FirearmRentalOptions /> },
  { path: '/ranges/experience', element: <Experience /> },
  { path: '/ranges/order-details', element: <OrderDetails /> },
];

// Account Routes
export const accountRoutes = [
  // Participants
  { path: '/accounts/participants', element: <Participants /> },

  // Guests
  { path: '/accounts/guests', element: <Guests /> },

  // Memberships
  { path: '/accounts/account', element: <Account /> },
  { path: '/membership-two', element: <MembershipTwo /> },

  { path: '/plan-individual', element: <PlanIndividual /> },

  // Purchase Product
  // { path: '/accounts/purchase-product', element: <PurchaseProduct /> },

  // Instructor
  { path: '/instructor', element: <Instructor /> },

  // Store
  { path: '/store/orders', element: <Orders /> },
  { path: '/store/product-order-details', element: <ProductOrderDetails /> },

  // Products
  { path: '/store/products', element: <Products /> },
  { path: '/store/add-form', element: <AddForm /> },
];

// Range Routes
export const rangeRoutes = [
  // Lane Bookings
  { path: '/ranges/up-coming', element: <UpComing /> },

  // Sub tab of Range
  { path: '/ranges/manage', element: <Manage /> },
  { path: '/ranges/manage-lanes', element: <ManageLanes /> },
  { path: '/ranges/availability', element: <Availability /> },

  // Range->Manage->FPE
  { path: '/ranges/create-qu', element: <CreateQuestions /> },

  // FPE Validation
  { path: '/ranges/user-details', element: <UserDetails /> },
  { path: '/ranges/cpe-validation-new', element: <CpeValidationNew /> },

  // ID Verification
  { path: '/ranges/id-verification-list', element: <IdVerificationList /> },

  // Lane CheckIn
  { path: '/ranges/lane-check-list', element: <LaneCheckList /> },
  { path: '/ranges/book-slot-user-add', element: <BookSlotUserAdd /> },

  // Class CheckIn
  { path: '/new/class', element: <Class /> },

  // { path: '/new/class-check-in-form', element: <ClassCheckInForm /> },
  { path: '/new/class-check-in-form', element: <CreateClassForm /> },

  // Range Activities
  { path: '/ranges/range-activities', element: <RangeActivities /> },

  // Booking
  { path: '/ranges/booking', element: <Booking /> },
];

// Create New Routes
export const createNewRoutes = [
  // Lane Bookings
  { path: '/ranges/up-coming', element: <UpComing /> },
];

// Upcoming Routes
export const upcomingRoutes = [
  // Class
  { path: '/new/class', element: <Class /> },
  // Lane Bookings
  { path: '/new/booking', element: <Booking /> },
  { path: '/new/class-details', element: <ClassDetails /> },
  { path: '/new/membership', element: <MembershipPurchase /> },

  // Events
  { path: '/upcoming/events', element: <Events /> },
  //  Sub page of Events
  { path: '/events-new', element: <EventsNew /> },

  // Categories
  { path: '/upcoming/event-categories', element: <EventCategories /> },

  // Attendees
  { path: '/upcoming/attendees', element: <Attendees /> },
  { path: '/order/receiptsnew', element: <Receiptsnew /> },
];

// Settings Routes
export const settingsRoutes = [
  // Range Locations
  { path: '/settings/range-locations', element: <RangeLocations /> },
  // Range create
  { path: '/settings/range-create', element: <CreateRangeForm /> },
  // Instructor
  { path: '/settings/instructor', element: <Instructor /> },

  // staff
  { path: '/settings/staff', element: <Staff /> },

  // Axis Config
  { path: '/settings/axis-config', element: <AxisConfig /> },

  // Plans
  { path: '/settings/plans', element: <Plans /> },
];
