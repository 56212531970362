import { Link } from 'react-router-dom';
import ButtonLoader from './ButtonLoader';
import ButtonRemoveImage from './ButtonRemoveImage';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Label from './Label';
// import axiosInstance from '../Utils/axiosInstance';
import * as Yup from 'yup';
import RichTextEditor from './RichTextEditor';
import { MEMBERSHIP_PLANS_ENDPOINT } from '../Utils/Endpoints';
import axiosInstance from '../Utils/axiosInstance';
import Toast from '../Utils/Toast';

const membershipSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(255, 'Name must not exceed 255 characters'),
  terms: Yup.string().nullable(),
  lengthMonths: Yup.number()
    .nullable()
    .min(0, 'Length months must be 0 or greater'),
  additionalFamilyMembers: Yup.number()
    .required('Additional family members is required')
    .min(0, 'Additional family members must be 0 or greater'),
  price: Yup.number()
    .required('Price is required')
    .min(0, 'Price must be 0 or greater'),
  isRecurring: Yup.boolean().required('Recurring status is required'),
  status: Yup.number()
    .required('Status is required')
    .oneOf([0, 1], 'Status must be either 0 or 1'),
  recurringBillingCycleMonths: Yup.number()
    .nullable()
    .min(0, 'Recurring billing cycle months must be 0 or greater'),
  recurringBillingCycleWeeks: Yup.number()
    .nullable()
    .min(0, 'Recurring billing cycle weeks must be 0 or greater'),
  recurringCharge: Yup.number().when('isRecurring', {
    is: true,
    then: (schema) =>
      schema
        .required('Recurring charge is required when recurring is enabled')
        .min(0, 'Recurring charge must be 0 or greater'),
    otherwise: (schema) =>
      schema.nullable().min(0, 'Recurring charge must be 0 or greater'),
  }),
});

const MembershipModal = ({
  selectedMembership,
  fetchMembershipPlans,
  setSelectedMembership,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [name, setName] = useState('');
  const [terms, setTerms] = useState(null);
  const [lengthMonths, setLengthMonths] = useState(0);
  const [additionalFamilyMembers, setAdditionalFamilyMembers] = useState(0);
  const [price, setPrice] = useState(0.0);
  const [isRecurring, setIsRecurring] = useState(false);
  const [status, setStatus] = useState(0);
  const [recurringBillingCycleMonths, setRecurringBillingCycleMonths] =
    useState(0);
  const [recurringBillingCycleWeeks, setRecurringBillingCycleWeeks] =
    useState(0);
  const [recurringCharge, setRecurringCharge] = useState(0.0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedMembership?.id) {
      setName(selectedMembership.name);
      setTerms(selectedMembership.terms || '');
      setLengthMonths(selectedMembership.length_months);
      setAdditionalFamilyMembers(selectedMembership.additional_family_members);
      setPrice(selectedMembership.price);
      setIsRecurring(selectedMembership.is_recurring);
      setStatus(selectedMembership.status);
      setRecurringBillingCycleMonths(
        selectedMembership.recurring_billing_cycle_months
      );
      setRecurringBillingCycleWeeks(
        selectedMembership.recurring_billing_cycle_weeks
      );
      setRecurringCharge(selectedMembership.recurring_charge);
    }
  }, [selectedMembership]);

  const validate = async () => {
    try {
      const formData = {
        name,
        terms,
        lengthMonths,
        additionalFamilyMembers,
        price,
        isRecurring,
        status,
        recurringBillingCycleMonths,
        recurringBillingCycleWeeks,
        recurringCharge,
      };

      await membershipSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationError) {
      const newErrors = {};
      validationError.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!(await validate())) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.put(
        `${MEMBERSHIP_PLANS_ENDPOINT}/${selectedMembership.id}`,
        {
          name: name, // required|string|max:255
          terms: terms, // nullable|string
          length_months: lengthMonths, // nullable|integer|min:0
          additional_family_members: additionalFamilyMembers, // required|integer|min:0
          price: price, // required|numeric|min:0
          is_recurring: isRecurring, // required|boolean
          recurring_billing_cycle_months: recurringBillingCycleMonths, // nullable|integer|min:0
          recurring_billing_cycle_weeks: recurringBillingCycleWeeks, // nullable|integer|min:0
          recurring_charge: recurringCharge, // required_if:is_recurring,true|numeric|min:0
          status: status, // required|in:0,1
        }
      );

      if (response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }
      const modalClose = document.getElementsByClassName('close-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      fetchMembershipPlans();
      Toast.success('Membership updated.', {
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  // MEMBERSHIP_PLANS_ENDPOINT
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(await validate())) return;
    try {
      setIsLoading(true);
      setGeneralError('');

      const response = await axiosInstance.post(MEMBERSHIP_PLANS_ENDPOINT, {
        name: name, // required|string|max:255
        terms: terms, // nullable|string
        length_months: lengthMonths, // nullable|integer|min:0
        additional_family_members: additionalFamilyMembers, // required|integer|min:0
        price: price, // required|numeric|min:0
        is_recurring: isRecurring, // required|boolean
        recurring_billing_cycle_months: recurringBillingCycleMonths, // nullable|integer|min:0
        recurring_billing_cycle_weeks: recurringBillingCycleWeeks, // nullable|integer|min:0
        recurring_charge: recurringCharge, // required_if:is_recurring,true|numeric|min:0
        status: status, // required|in:0,1
      });

      if (response.data.code !== 201 && response.data.code !== 200) {
        setGeneralError(response.data.message);
        return;
      }

      const modalClose = document.getElementsByClassName('close-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      fetchMembershipPlans();
      Toast.success('Membership created.', {
        autoClose: 2000,
      });
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setName('');
    setTerms('');
    setLengthMonths(0);
    setAdditionalFamilyMembers(0);
    setPrice(0.0);
    setIsRecurring(false);
    setStatus(0);
    setRecurringBillingCycleMonths(0);
    setRecurringBillingCycleWeeks(0);
    setRecurringCharge(0.0);
    setErrors({});
    setGeneralError('');
    setSelectedMembership(null);
  };

  return (
    <div
      className="modal fade"
      id="membershipModal"
      aria-labelledby="membershipModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title lane-name" id="membershipModalLabel">
              {selectedMembership?.id ? 'Edit Membership' : 'Add Membership'}
            </h5>
            <a
              className="close-firearm-2 close-booking-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src="../assets/icon/cross-svgrepo-com.svg" alt="" />
            </a>
          </div>
          <form onSubmit={selectedMembership?.id ? handleUpdate : handleSubmit}>
            <div className="modal-body modal-height">
              <div className="row">
                <div className="col-lg-12">
                  <Label labelHeading="Name:" />
                  <div className="tickets ">
                    <input
                      type="text"
                      className="form-control input-theme"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                  </div>
                </div>

                <div className="col-lg-6">
                  <Label labelHeading="Length (Months):" />
                  <div className="tickets">
                    <input
                      type="number"
                      className="form-control input-theme"
                      value={lengthMonths}
                      onChange={(e) => setLengthMonths(Number(e.target.value))}
                    />
                    {errors.lengthMonths && (
                      <div className="error">{errors.lengthMonths}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <Label labelHeading="Additional Family Members:" />
                  <div className="tickets">
                    <input
                      type="number"
                      className="form-control input-theme"
                      value={additionalFamilyMembers}
                      onChange={(e) =>
                        setAdditionalFamilyMembers(Number(e.target.value))
                      }
                    />
                    {errors.additionalFamilyMembers && (
                      <div className="error">
                        {errors.additionalFamilyMembers}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <Label labelHeading="Price:" />
                  <div className="tickets">
                    <input
                      type="number"
                      step="0.01"
                      className="form-control input-theme"
                      value={price}
                      onChange={(e) => setPrice(Number(e.target.value))}
                    />
                    {errors.price && (
                      <div className="error">{errors.price}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <Label labelHeading="Recurring:" />
                  <div className="form-switch text-left">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isRecurring}
                      onChange={(e) => setIsRecurring(e.target.checked)}
                    />
                    {errors.isRecurring && (
                      <div className="error">{errors.isRecurring}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <Label labelHeading="Status:" />
                  <div className="tickets">
                    <select
                      className="form-control input-theme"
                      value={status}
                      onChange={(e) => setStatus(Number(e.target.value))}
                    >
                      <option value={0}>Inactive</option>
                      <option value={1}>Active</option>
                    </select>
                    {errors.status && (
                      <div className="error">{errors.status}</div>
                    )}
                  </div>
                </div>

                {isRecurring && (
                  <>
                    <div className="col-lg-6">
                      <Label labelHeading="Recurring Billing Cycle (Months):" />
                      <div className="tickets">
                        <input
                          type="number"
                          className="form-control input-theme"
                          value={recurringBillingCycleMonths}
                          onChange={(e) =>
                            setRecurringBillingCycleMonths(
                              Number(e.target.value)
                            )
                          }
                        />
                        {errors.recurringBillingCycleMonths && (
                          <div className="error">
                            {errors.recurringBillingCycleMonths}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <Label labelHeading="Recurring Billing Cycle (Weeks):" />
                      <div className="tickets">
                        <input
                          type="number"
                          className="form-control input-theme"
                          value={recurringBillingCycleWeeks}
                          onChange={(e) =>
                            setRecurringBillingCycleWeeks(
                              Number(e.target.value)
                            )
                          }
                        />
                        {errors.recurringBillingCycleWeeks && (
                          <div className="error">
                            {errors.recurringBillingCycleWeeks}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <Label labelHeading="Recurring Charge:" />
                      <div className="tickets">
                        <input
                          type="number"
                          step="0.01"
                          className="form-control input-theme"
                          value={recurringCharge}
                          onChange={(e) =>
                            setRecurringCharge(Number(e.target.value))
                          }
                        />
                        {errors.recurringCharge && (
                          <div className="error">{errors.recurringCharge}</div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="col-lg-12">
                  <Label labelHeading="Terms:" />
                  <div className="multiselect-container multiSelectContainer tickets-text">
                    <RichTextEditor
                      value={terms}
                      setEditorData={setTerms}
                      index="terms"
                    />
                    {errors.terms && (
                      <div className="error">{errors.terms}</div>
                    )}
                  </div>
                </div>
              </div>
              {generalError && (
                <p className="error text-center mt-3">{generalError}</p>
              )}
            </div>

            <div className="modal-footer border-0 justify-content-center">
              <div className="button-range-create">
                <ButtonLoader
                  type="submit"
                  bgChange={`white-bg w-pd-add `}
                  active="orange-outline"
                  isLoading={isLoading}
                >
                  {selectedMembership?.id ? 'Update' : 'Save '}
                </ButtonLoader>
                <Link
                  to="#"
                  className="close-modal"
                  onClick={handleReset}
                  data-bs-dismiss="modal"
                >
                  <ButtonRemoveImage
                    Heading="Cancel"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

MembershipModal.propTypes = {
  selectedMembership: PropTypes.object,
  fetchMembershipPlans: PropTypes.func,
  setSelectedMembership: PropTypes.func,
};
export default MembershipModal;
