import React from 'react';
import OptionModal from './OptionModal';
import { Link } from 'react-router-dom';
import ButtonAdd from './ButtonAdd';
import FPECard from './FPECard';
import PropTypes from 'prop-types';
import RangeNotFound from './RangeNotFound';
import SkeletonLoader from './SkeletonLoader';

const FPE = ({ firearm, handleDelete, fetchFirearm, isLoading }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card-body ">
            <div className="employees employ-set">
              <div className="back-listing-tab">
                <Link to="/settings/range-locations" className="button-width">
                  <ButtonAdd
                    Heading=""
                    images="../assets/icon/back-icon-page.svg"
                    active="orange-outline"
                    bgChange="white-bg "
                  />
                </Link>
                <h3 className="card-top-2">FPE PROCESS</h3>
              </div>
              <div className="all-date-selected new-back-btn-add">
                <Link
                  to="/settings/range-locations"
                  className="button-width b-p"
                >
                  <ButtonAdd
                    Heading=""
                    images="../assets/icon/back-icon-page.svg"
                    active="orange-outline"
                    bgChange="white-bg "
                  />
                </Link>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#OptionModal"
                  className="button-width"
                >
                  <ButtonAdd
                    Heading="ADD"
                    images="../assets/icon/plaus_icon.svg"
                    active="orange-outline"
                    bgChange="white-bg w-pd-add"
                  />
                </Link>
              </div>
            </div>
            <div className="triangle-top">
              <div className="body-text">
                <div className="row">
                  {firearm?.length === 0 && !isLoading && (
                    <RangeNotFound title="FPE Process" />
                  )}
                  {firearm?.length > 0 && (
                    <div className="card-gun-id c-g-id">
                      {firearm?.map((item, index) => (
                        <FPECard
                          hexBg="bg-black"
                          hexOuterLine="bg-width-outline"
                          gunImage={
                            item?.logo?.fullUrl
                              ? item.logo.fullUrl
                              : '../assets/icon/handgun.svg'
                          }
                          FPEHeading={item.proficiency_type.name}
                          key={index}
                          firearmData={item}
                          imgSize="bb"
                          handleDelete={handleDelete}
                        />
                      ))}
                    </div>
                  )}
                  {isLoading && <SkeletonLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OptionModal fetchFirearm={fetchFirearm} />
    </>
  );
};

FPE.propTypes = {
  firearm: PropTypes.array,
  handleDelete: PropTypes.func,
  fetchFirearm: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default FPE;
