import React from 'react';
import CardDesk from '../Components/CardDesk';
import CardGlance from '../Components/CardGlance';
import ProgressItems from '../Components/ProgressItems';
import { useNavigate } from 'react-router';

const Index = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="outer-border outer-overflow-show">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7">
              <CardDesk />
              <CardGlance />
            </div>
            <div className="col-xl-5 g-lg-0 gx-xl-5 pb-5">
              <div
                className="user-card-range triangle-card cursor-p"
                onClick={() => navigate('/today')}
              >
                <div className="on-range">
                  <h3 className="sub-heading">ON-RANGE TODAY</h3>
                  <div className="user-total">
                    <p>Total Visitors Checked In</p>
                    <h3 className="theme-heading">7</h3>
                  </div>
                </div>
                <div className="training-2">
                  <ProgressItems
                    classImage="../assets/icon/inclass.svg"
                    className="In class"
                    classList="2"
                  />
                  <ProgressItems
                    classImage="../assets/icon/inprivate_training.svg"
                    className="In Private"
                    classList="1"
                  />
                  <ProgressItems
                    classImage="../assets/icon/lane_checkin.svg"
                    className="On-Lane"
                    classList="4"
                  />

                  {/* <div className="class-users">
                                            <div className="progress-border">
                                                <HexLine imageUserTwo="../assets/icon/inclass.svg" imgSizeTwo="size-cover-2" hexOuterLine="bg-line-p" />
                                            </div>
                                            <p>
                                                In class
                                            </p>
                                            <h3>
                                                2
                                            </h3>
                                        </div> */}
                  {/* <div className="class-users">
                                            <div className="progress-border">
                                                <HexLine imageUserTwo="../assets/icon/inprivate_training.svg" />

                                            </div>
                                            <p>
                                                In Private
                                            </p>
                                            <h3>
                                                1
                                            </h3>
                                        </div> */}
                  {/* <div className="class-users">
                                            <div className="progress-border ">
                                                <HexLine imageUserTwo="../assets/icon/lane_checkin.svg" hexOuterLine="bg-line-p" />
                                            </div>
                                            <p>On-Lane</p>
                                            <h3>4</h3>
                                        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
