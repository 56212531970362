import React, { useState } from 'react';
import ButtonRemoveImage from './ButtonRemoveImage';
import { Link } from 'react-router-dom';
import Label from './Label';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Toast from '../Utils/Toast';
import ButtonLoader from './ButtonLoader';
import RichTextEditor from './RichTextEditor';
import { getObject, saveObject } from '../Utils/LocalStorage';

// Validation schema
const SamartWavierSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

const ModalSamartWavier = ({ fetchSmartWaiver }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    try {
      SamartWavierSchema.validateSync(
        {
          name,
          description,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);

      setGeneralError('');
      const respObj = {
        name,
        description,
        active,
      };
      // Step 1: Retrieve existing waiverList from localStorage
      const existingWaivers = JSON.parse(getObject('waiverList')) || [];

      // Step 2: Add the new waiver object to the array
      existingWaivers.push(respObj);

      // Step 3: Save the updated array back to localStorage
      saveObject('waiverList', JSON.stringify(existingWaivers));
      handleReset();
      const modalClose = document.getElementsByClassName('close-wavier-modal');
      for (let i = 0; i < modalClose.length; i++) {
        modalClose[i].click();
      }
      Toast.success('Waiver successfully modified.', {
        autoClose: 2000,
      });
      fetchSmartWaiver();
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setName('');
    setDescription('');
    setGeneralError('');
    setErrors({});
    setActive(false);
  };

  return (
    <>
      <div
        className="modal fade"
        id="modalSmartWaiver"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="lane-name">Waiver</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body modal-height">
                <div className="row">
                  <div className="col-lg-12">
                    <Label labelHeading="Name:" />
                    <div className="input-search tickets">
                      <input
                        type="text"
                        className="form-control input-theme mb-0"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      {errors.name && (
                        <div className="error">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3 mt-lg-0">
                    <Label labelHeading="Description:" />
                    <div className="input-search tickets">
                      <RichTextEditor
                        value={description}
                        setEditorData={setDescription}
                        index="description"
                      />
                      {errors.description && (
                        <div className="error">{errors.description}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="active"
                        onChange={(e) => setActive(e.target.checked)}
                        checked={active}
                      />
                      <label className="form-check-label" htmlFor="active">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                {generalError && (
                  <p className="error text-center mt-3">{generalError}</p>
                )}
              </div>
              <div className="modal-footer border-0 justify-content-center">
                <ButtonLoader
                  isLoading={isLoading}
                  type="submit"
                  active="orange-outline"
                  bgChange="bg-orange w-pd-add save-w"
                >
                  Save
                </ButtonLoader>

                <Link
                  data-bs-dismiss="modal"
                  className="close-wavier-modal"
                  onClick={handleReset}
                >
                  <ButtonRemoveImage
                    Heading="Cancel"
                    active="gary-light-outline"
                    bgChange="gary-bg w-pd-add"
                  />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

ModalSamartWavier.propTypes = {
  headingBay: PropTypes.string,
  selectedBay: PropTypes.object,
  setSelectedBay: PropTypes.func,
  fetchSmartWaiver: PropTypes.func,
};

export default ModalSamartWavier;
