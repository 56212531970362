import axiosInstance from './axiosInstance';
import Logger from './Logger';
import Toast from './Toast';
import { ORDER_INIT_ENDPOINT, ORDER_PROCEED_ENDPOINT } from './Endpoints';
import { loadStripe } from '@stripe/stripe-js';

class PaymentService {
  static async initializeOrder(rangeId, orderData, selectedSlot) {
    try {
      const response = await axiosInstance.post(
        `${ORDER_INIT_ENDPOINT}/${rangeId}`,
        {
          ...orderData,
          items: [
            {
              ...orderData.items[0],
              details: {
                ...orderData.items[0].details,
                from_slot: selectedSlot.start_unix_timestamp,
                to_slot: selectedSlot.end_unix_timestamp,
              },
            },
          ],
        }
      );

      if (response.data.code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      Logger.error('Error initializing order:', error);
      throw error;
    }
  }

  static async createStripeSource(customerId, paymentMethodId, stripeData) {
    try {
      // Initialize Stripe with secret key
      const stripe = await loadStripe(
        stripeData.details.test_mode
          ? stripeData.details.test_secret_key
          : stripeData.details.secret_key
      );

      // Create source directly using Stripe API
      const source = await stripe.customers.createSource(customerId, {
        source: paymentMethodId,
      });
      //   const source = await stripe.customers.createSource(customerId, {
      //     source: paymentMethodId,
      //   });

      if (source) {
        return source;
      } else {
        throw new Error('Failed to create Stripe source');
      }
    } catch (error) {
      Logger.error('Error creating Stripe source:', error);
      throw error;
    }
  }

  static async processStripePayment(rangeId, orderId, cardId) {
    try {
      const response = await axiosInstance.post(
        `${ORDER_PROCEED_ENDPOINT}/${rangeId}/${orderId}`,
        {
          payment_method_id: 1, // Stripe payment method ID
          card_id: cardId,
          noted: '',
        }
      );

      if (response.data.code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      Logger.error('Error processing Stripe payment:', error);
      throw error;
    }
  }

  static async processCashPayment(rangeId, orderId) {
    try {
      const response = await axiosInstance.post(
        `${ORDER_PROCEED_ENDPOINT}/${rangeId}/${orderId}`,
        {
          payment_method_id: 2, // Cash payment method ID
          card_id: '',
          noted: '',
        }
      );

      if (response.data.code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      Logger.error('Error processing cash payment:', error);
      throw error;
    }
  }

  static async handleStripePayment(paymentData) {
    const {
      rangeId,
      orderData,
      //   stripePaymentMethodId,
      selectedSlot,
      onSuccess,
      onError,
      stripeData,
      cardId,
    } = paymentData;

    try {
      // Step 1: Initialize order to get customer ID
      const initResponse = await this.initializeOrder(
        rangeId,
        orderData,
        selectedSlot
      );
      console.log(initResponse, stripeData);
      const orderId = initResponse.id;
      //   const customerId = initResponse.user.stripe_customer_id;

      //   // Step 2: Create Stripe source using customer ID and payment method
      //   const sourceResponse = await this.createStripeSource(
      //     customerId,
      //     stripePaymentMethodId
      //   );
      //   const cardId = sourceResponse.id;

      // Step 3: Process payment with the card ID
      await this.processStripePayment(rangeId, orderId, cardId);

      // Step 4: Handle success
      Toast.success('Payment processed successfully');
      if (onSuccess) onSuccess();
    } catch (error) {
      Toast.error(error.message || 'Payment failed');
      if (onError) onError(error);
    }
  }

  static async handlePayment(paymentData) {
    const {
      rangeId,
      orderData,
      paymentMethod,
      stripePaymentMethodId,
      selectedSlot,
      onSuccess,
      onError,
      stripeData,
      cardId,
    } = paymentData;

    try {
      if (paymentMethod === 'stripe' && stripePaymentMethodId) {
        await this.handleStripePayment({
          rangeId,
          orderData,
          stripePaymentMethodId,
          selectedSlot,
          onSuccess,
          onError,
          stripeData,
          cardId,
        });
      } else if (paymentMethod === 'cash') {
        // Step 1: Initialize order
        const initResponse = await this.initializeOrder(
          rangeId,
          orderData,
          selectedSlot
        );
        const orderId = initResponse.id;

        // Step 2: Process cash payment
        await this.processCashPayment(rangeId, orderId);

        // Step 3: Handle success
        Toast.success('Payment processed successfully');
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      Toast.error(error.message || 'Payment failed');
      if (onError) onError(error);
    }
  }
}

export default PaymentService;
