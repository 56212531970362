import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ButtonAdd from '../Components/ButtonAdd';

const PlanIndividual = () => {
  const { plan } = useLocation().state;

  return (
    <>
      <div className="outer-border outer-over-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set">
                  <div className="back-listing-tab">
                    <Link to="/settings/plans" className="button-width">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                    <h3 className="card-top-2 h-set-2">{plan?.name}</h3>
                  </div>
                  <div className="all-date-selected mt-3 new-back-btn-add">
                    <Link to="/settings/plans" className="button-width b-p">
                      <ButtonAdd
                        Heading=""
                        images="../assets/icon/back-icon-page.svg"
                        active="orange-outline"
                        bgChange="white-bg "
                      />
                    </Link>
                  </div>
                </div>
                <div className="triangle-top">
                  <div className="body-text">
                    <div className="table-responsive w-100">
                      <table className="table table-time-gun-create ">
                        <tbody className="table-time-gun-create__old-even create-plan text-start">
                          <tr>
                            <th>
                              <p className="t-b-t">
                                <span>Name</span>
                                <span>:</span>
                              </p>
                            </th>
                            <td>{plan?.name}</td>
                          </tr>
                          <tr>
                            <th>
                              <p className="t-b-t">
                                <span>Membership Terms</span>
                                <span>:</span>
                              </p>
                            </th>
                            <td>{plan?.terms}</td>
                          </tr>

                          <tr>
                            <th>
                              <p className="t-b-t">
                                <span>Membership Cost</span>
                                <span>:</span>
                              </p>
                            </th>
                            <td>${plan?.price}</td>
                          </tr>

                          <tr>
                            <th>
                              <p className="t-b-t">
                                <span>Recurring Amount</span>
                                <span>:</span>
                              </p>
                            </th>
                            <td>${plan?.recurring_charge}</td>
                          </tr>
                          <tr>
                            <th>
                              <p className="t-b-t">
                                <span>Membership Length</span>
                                <span>:</span>
                              </p>{' '}
                            </th>
                            <td>
                              {plan?.length_months ? plan?.length_months : '-'}{' '}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p className="t-b-t">
                                <span>Recurring Billing Period</span>
                                <span>:</span>
                              </p>
                            </th>
                            <td>
                              {plan?.recurring_billing_cycle_months === 12
                                ? 'Monthly'
                                : plan?.recurring_billing_cycle_months === 0
                                  ? '-'
                                  : 'Yearly'}
                            </td>
                          </tr>
                          <tr>
                            <th className="vv">
                              <p className="t-b-t">
                                <span>Features</span>
                                <span>:</span>
                              </p>
                            </th>
                            <td>
                              <ul>
                                <li>Unlimited & Priority Range Shooting</li>
                                <li>10% Off Ammunition</li>
                                <li>
                                  50% Off Handgun, Rifle, and Machine Gun
                                  Rentals
                                </li>
                                <li>
                                  Guest Passes Per Year (member must accompany
                                  guest)
                                </li>
                                <li>
                                  10% Off Classes Reservations Shooting Events,
                                  Leagues and SYNC
                                </li>
                                <li>Free $10 Monthly Add-On</li>
                                <li>30% Off FFL Transfers</li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanIndividual;
