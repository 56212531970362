import React, { useState } from 'react';
import Label from '../Components/Label';
import { Link, useNavigate } from 'react-router-dom';
import ButtonRemoveImage from '../Components/ButtonRemoveImage';
import axiosInstance from '../Utils/axiosInstance';
import { RANGE_ENDPOINT } from '../Utils/Endpoints';
import * as Yup from 'yup';
import RichTextEditor from '../Components/RichTextEditor';
import { emailRegExp, phoneRegExp } from '../Utils/common';
import Toast from '../Utils/Toast';
import ButtonLoader from '../Components/ButtonLoader';

// Validation schema
const CreateRangeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  subHeading: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  policy: Yup.string().required('Required'),
  membershipPolicy: Yup.string().required('Required'),
  isShotproPartner: Yup.string().required('Required'),
});

const CreateRange = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [policy, setPolicy] = useState('');
  const [membershipPolicy, setMembershipPolicy] = useState('');
  const [isShotproPartner, setIsShotproPartner] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    try {
      CreateRangeSchema.validateSync(
        {
          name,
          subHeading,
          description,
          email,
          phoneNumber,
          policy,
          membershipPolicy,
          isShotproPartner,
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    try {
      setIsLoading(true);
      setGeneralError('');
      const response = await axiosInstance.post(RANGE_ENDPOINT, {
        name: name,
        sub_heading: subHeading,
        description: description,
        email: email,
        phone_number: phoneNumber,
        policy: policy,
        membership_policy: membershipPolicy,
        is_shotpro_partner: isShotproPartner,
      });
      if (response.data.code === 200) {
        handleReset();
        Toast.success('Range created.', {
          onClose: () => navigate('/settings/range-locations'),
          autoClose: 2000,
        });
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setSubHeading('');
    setName('');
    setDescription('');
    setIsShotproPartner(false);
    setMembershipPolicy('');
    setPhoneNumber('');
    setPolicy('');
    setEmail('');
    setGeneralError('');
    setErrors({});
  };

  return (
    <>
      <div className="outer-border">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body">
                <div className="employees employ-set pt-4 pt-md-1">
                  <h3 className="card-top-2">Create Range</h3>
                  <div className="all-date-selected mobile-b-set d-none">
                    <Link to="#" className="button-width">
                      <ButtonRemoveImage
                        Heading="NEXT"
                        active="orange-outline"
                        bgChange="white-bg w-pd-add"
                      />
                    </Link>
                    <Link to="/events" className="button-width">
                      <ButtonRemoveImage
                        Heading="Back"
                        active="gary-light-outline"
                        bgChange="gary-bg w-pd-add"
                      />
                    </Link>
                  </div>
                </div>
                <form className="triangle-top" onSubmit={handleSubmit}>
                  <div className="body-text">
                    <div className="row">
                      <div className="col-md-6">
                        <Label labelHeading="Name:" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {errors.name && (
                            <div className="error">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Label labelHeading="Address:" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {errors.name && (
                            <div className="error">{errors.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Label labelHeading="Description:" />
                        <div className="multiselect-container multiSelectContainer tickets-text">
                          <RichTextEditor
                            value={description}
                            setEditorData={setDescription}
                            index="description"
                          />
                          {errors.description && (
                            <div className="error">{errors.description}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <Label labelHeading="Sub Heading:" />
                        <div className="tickets">
                          <input
                            type="text"
                            className="form-control input-theme"
                            value={subHeading}
                            onChange={(e) => setSubHeading(e.target.value)}
                          />
                          {errors.subHeading && (
                            <div className="error">{errors.subHeading}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-0 mt-md-3">
                        <Label labelHeading="Phone:" />
                        <div className="tickets">
                          <input
                            type="phone"
                            className="form-control input-theme"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                          {errors.phoneNumber && (
                            <div className="error">{errors.phoneNumber}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <Label labelHeading="Email:" />
                        <div className="tickets">
                          <input
                            type="email"
                            className="form-control input-theme"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && (
                            <div className="error">{errors.email}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Label labelHeading="Policy:" />
                        <div className="multiselect-container multiSelectContainer tickets-text">
                          <RichTextEditor
                            value={policy}
                            setEditorData={setPolicy}
                            index="policy"
                          />
                          {errors.policy && (
                            <div className="error">{errors.policy}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mt-md-0">
                        <Label labelHeading="Membership Policy:" />
                        <div className="multiselect-container multiSelectContainer tickets-text">
                          <RichTextEditor
                            value={membershipPolicy}
                            setEditorData={setMembershipPolicy}
                            index="membershipPolicy"
                          />
                          {errors.membershipPolicy && (
                            <div className="error">
                              {errors.membershipPolicy}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 pt-3">
                        <Label labelHeading="Shotpro Partner:" />
                        <div className="form-switch text-left">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={isShotproPartner}
                            onChange={(e) =>
                              setIsShotproPartner(e.target.checked)
                            }
                          />
                          {errors.isShotproPartner && (
                            <div className="error">
                              {errors.isShotproPartner}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <Label labelHeading="Logo:" />
                        <div className="button-wrapper">
                          <span className="label-input">
                            <strong>Logo Image</strong>
                            Selected files are not actually uploaded.
                            Recommended Size 1920 X 700
                          </span>
                          <input
                            type="file"
                            name="upload"
                            id="upload"
                            className="upload-box input-theme"
                          />
                        </div>
                      </div>

                      <div className="col-md-4"></div>
                      <div className="button-range-create mt-5 ps-0 ps-md-3">
                        <ButtonLoader
                          type="submit"
                          bgChange="white-bg w-pd-add"
                          active="orange-outline"
                          isLoading={isLoading}
                        >
                          Create Range
                        </ButtonLoader>
                        <Link to="#" className="" onClick={handleReset}>
                          <ButtonRemoveImage
                            Heading="Cancel"
                            active="gary-light-outline"
                            bgChange="gary-bg w-pd-add"
                          />
                        </Link>
                      </div>
                      {generalError && <p className="error">{generalError}</p>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRange;
